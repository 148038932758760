<template>
    <div class="sizes" v-if="lengths.length > 0 && lengths[0] != null && lengths.some((length) => length !== '')">
        <div class="sizes__title">
            <div>
                {{ title }}
            </div>
            <template v-for="category in category_all" :key="category.id">
                <div v-if="categoryIdSizes.includes(category.id)" class="sizes__link">
                    <span @click="clickShowSizes(category.id)">Як визначити розмір?</span>
                </div>
            </template>
        </div>
        <div class="sizes__items">
            <div class="sizes__item" v-for="(item, index) in lengths" :key="index" :class="{ active: activeIndex === index }" @click="selectLength(index)">
                {{ item }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LengthsProduct",
    props: {
        title: String,
        lengths: Array,
        category_all: Array,
        selectedLength: String, // Новый пропс для выбранной длины
    },
    data() {
        return {
            activeIndex: 0,
            categoryIdSizes: [34, 19],
        };
    },
    watch: {
        selectedLength: {
            immediate: true,
            handler(newLength) {
                if (newLength) {
                    this.activeIndex = this.lengths.indexOf(newLength);
                }
            },
        },
    },
    methods: {
        selectLength(index) {
            this.activeIndex = index;
            this.$emit("selected", this.lengths[index]);
        },
        clickShowSizes(categoryId) {
            this.$emit("showPopupSizes", { categoryId: categoryId, time: Date.now() });
        },
        hasSpecificId(id) {
            return this.specificId === id;
        },
    },
};
</script>

<style>
.sizes__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sizes__link {
    text-decoration: underline;
    cursor: pointer;
}
</style>
