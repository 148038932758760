<template>
    <div :class="{ filter__chekbox: true, chekbox: true, active: isCheked }" @click="handleCheckboxClick">
        <div class="chekbox__box"></div>
        <slot></slot>
        <div class="chekbox__title" v-if="title">{{ title }}</div>
    </div>
</template>

<script>
export default {
    name: "ChecboxFilterSpecial",
    props: {
        title: String,
        isChecked: Boolean,
    },
    data() {
        return {
            isCheked: false,
        };
    },
    methods: {
        handleCheckboxClick() {
            this.isCheked = !this.isCheked;
            this.$emit("change", this.isCheked ? 1 : 0); // Отправляем 1 или 0 в зависимости от значения isCheked
        },
    },
};
</script>

<style></style>
