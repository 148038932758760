<template>
    <div class="sizes" v-if="sizes.length > 0 && this.sizes[0] !== null && sizes.some((size) => size !== '')">
        <div class="sizes__title">{{ title }}</div>
        <div class="sizes__items">
            <div class="sizes__item" v-for="(item, index) in sizes" :key="index" :class="{ active: activeIndex === index }" @click="selectSize(index, item)">{{ parseFloat(item.price) }} грн</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SizesProduct",
    props: {
        title: String,
        sizes: Array,
    },
    data() {
        return {
            activeIndex: 0, // Индекс активного элемента по умолчанию установлен на первый элемент
            selectedVariant: null, // Изначально установлено в null
        };
    },
    methods: {
        selectSize(index, item) {
            this.activeIndex = index;
            this.selectedVariant = {
                id: item.id,
                product_id: item.product_id,
                variant_id: item.id,
                vendor_code: item.vendor_code,
                price: item.price,
                price_origin: item.price_origin,
                percent: item.percent ?? 0,
                old_price: null,
                is_set: 0,
                quantity: 1,
                variant: item,
            };

            this.$emit("selected", this.selectedVariant);
        },
        selectFirstSize() {
            if (this.sizes.length > 0 && this.sizes[0] !== null && this.sizes.some((size) => size !== "")) {
                this.selectSize(0, this.sizes[0]);
            }
        },
    },
    mounted() {
        this.selectFirstSize();
    },
    watch: {
        sizes: {
            immediate: true,
            handler(newSizes) {
                if (newSizes.length > 0 && newSizes[0] !== null && newSizes.some((size) => size !== "")) {
                    this.selectFirstSize();
                }
            },
        },
    },
};
</script>

<style></style>
