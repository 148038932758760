import { createStore } from "vuex";
import { cart } from "./cart";
import { global } from "./global";

export default createStore({
    modules: {
        cart: cart,
        global: global,
    },
});
