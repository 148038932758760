<template>
    <section class="product-block">
        <div class="product-block__container">
            <div class="product-block__body">
                <div class="product-block__top top-block">
                    <router-link :to="{ name: 'Catalog', params: { categoryId: category_id } }" class="top-block__title title">{{ title }}</router-link>
                    <router-link :to="{ name: 'Catalog', params: { categoryId: category_id } }" class="top-block__link _icon-next">дивитись усі</router-link>
                </div>
                <div class="product-block__items product-items">
                    <ProductCart v-for="(product, index) in (products || []).slice(0, 8)" :key="index" :product="product" />
                </div>
                <!-- <button class="product-block__button button">Показати більше</button> -->
                <div class="product-block__button-container">
                    <router-link :to="{ name: 'Catalog', params: { categoryId: category_id } }" class="product-block__button button">Показати більше</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ProductCart from "./ProductCart.vue";

export default {
    components: {
        ProductCart,
    },
    props: {
        id: {
            type: Number,
        },
        category_id: {
            type: Number,
        },
        title: {
            type: String,
        },
        slug: {
            type: String,
        },
        products: {
            type: Array,
        },
    },
    data() {
        return {};
    },
    methods: {},
    mounted() {},
};
</script>

<style>
.product-block__button-container {
    display: flex;
}
</style>
