<template>
    <div>
        <div class="roulette-container">
            <canvas ref="canvas" width="320" height="320"></canvas>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from "vue";

export default {
    name: "RouletteComponent",
    props: {
        spinActive: Boolean,
    },
    methods: {
        launchWheel() {
            this.$refs.rouletteComponent.spin(); // Вызовите метод spin
        },
    },
    mounted() {
        this.rouletteComponent = this.$refs.rouletteComponent;
    },
    setup() {
        const instance = getCurrentInstance();

        const canvas = ref(null);
        let ctx;
        const options = ["-2%", "-10%", "-5%", "-7%", "-2%", "-10%", "-2%", "-7%"];
        const probabilities = [1, 0, 0, 0, 1, 0, 1, 0];
        let startAngle = 0;
        const arc = Math.PI / (options.length / 2);
        let spinAngleStart;
        let spinTime = 0;
        let spinTimeTotal = 0;

        function drawRouletteWheel() {
            if (canvas.value.getContext) {
                const outsideRadius = 160;
                const textRadius = outsideRadius * 0.75;
                const insideRadius = 0;

                ctx = canvas.value.getContext("2d");
                ctx.clearRect(0, 0, 320, 320);

                const colors = ["#fff", "#FF77DC", "#fff", "#FF77DC", "#fff", "#FF77DC", "#fff", "#FF77DC"];
                const colorsText = ["#FF77DC", "#fff", "#FF77DC", "#fff", "#FF77DC", "#fff", "#FF77DC", "#fff"];

                ctx.strokeStyle = "#fff0";
                ctx.lineWidth = 2;

                ctx.font = "bold 30px Century Gothi";

                for (let i = 0; i < options.length; i++) {
                    const angle = startAngle + i * arc;
                    ctx.fillStyle = colors[i];

                    ctx.beginPath();
                    ctx.arc(160, 160, outsideRadius, angle, angle + arc, false);
                    ctx.arc(160, 160, insideRadius, angle + arc, angle, true);
                    ctx.stroke();
                    ctx.fill();

                    ctx.save();
                    ctx.fillStyle = colorsText[i];
                    var newTextRadius = textRadius * 0.75; // Например, уменьшите радиус до 75% от текущего значения

                    ctx.translate(160 + Math.cos(angle + arc / 1.6) * newTextRadius, 160 + Math.sin(angle + arc / 1.6) * newTextRadius);
                    ctx.rotate(angle + arc / 3 + Math.PI / 100); // Верните обратно вращение к arc / 2 + Math.PI / 2
                    const text = options[i];
                    ctx.fillText(text, -ctx.measureText(text).width / 2, 0);
                    ctx.restore();
                }
            }
        }

        function spin() {
            spinAngleStart = Math.random() * 10 + 10;
            spinTime = 0;
            spinTimeTotal = Math.random() * 3 + 4 * 1000;
            rotateWheel();
        }

        function rotateWheel() {
            spinTime += 30;

            const degrees = (startAngle * 180) / Math.PI + 90;
            const arcd = (arc * 180) / Math.PI;
            const index = Math.floor((360 - (degrees % 360)) / arcd);
            if (probabilities[index] === 0) {
                spinTimeTotal += 40;
            }
            const shouldContinueSpinning = spinTime >= spinTimeTotal && probabilities[index] === 0;

            if (spinTime >= spinTimeTotal && !shouldContinueSpinning) {
                stopRotateWheel();
                return;
            }
            const spinAngle = spinAngleStart - easeOut(spinTime, 0, spinAngleStart, spinTimeTotal);
            startAngle += (spinAngle * Math.PI) / 180;
            drawRouletteWheel();
            setTimeout(rotateWheel, 30);
        }

        function stopRotateWheel() {
            const degrees = (startAngle * 180) / Math.PI + 90;
            const arcd = (arc * 180) / Math.PI;
            const index = Math.floor((360 - (degrees % 360)) / arcd);
            const text = options[index];

            instance.emit("wheelStopped", text); // Испускаем событие с данными
        }

        function easeOut(t, b, c, d) {
            const ts = (t /= d) * t;
            const tc = ts * t;
            return b + c * (tc + -3 * ts + 3 * t);
        }

        onMounted(drawRouletteWheel);

        return { canvas, spin };
    },
};
</script>

<style scoped>
.roulette-container canvas {
    width: 265px;
}
.roulette-container {
    border-radius: 100%;
    border: 20px solid #ff77dc;
    width: 335px;
    height: 335px;
    display: flex;
    padding: 15px;
    position: relative;
}

.roulette-container::before {
    content: url("../assets/img/arrow-roullete.svg");
    position: absolute;
    top: -90px;
    left: 50%;
    transform: translate(-50%, 0%);
}
</style>
