<template>
    <div class="order__chekboxs order__chekboxs-row">
        <div class="order__chekboxs">
            <div v-for="(item, index) in chekboxItems" :key="index" :class="{ order__chekbox: true, 'chekbox-order': true, active: selectedOption === item }" @click="selectOption(item)">
                <div class="chekbox-order__top">
                    <img :src="item.image" alt="" />
                </div>
                <div class="chekbox-order__text">{{ item.text }}</div>
            </div>
        </div>

        <div>
            <div class="order__chekboxs-payments-commisions">
                <span>{{ commissionText }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ChekboxesOrder",
    data() {
        return {
            selectedOption: null,
            chekboxItems: this.items,
            commissionText: "Комісія 0%: Apple Pay, Google Pay, Платіжні картки",
        };
    },
    props: {
        items: Array,
    },
    created() {
        this.selectedOption = this.chekboxItems[0];
        this.$emit("selected-pay-method", this.selectedOption); // Эмитируем событие с выбранным методом оплаты в родительский компонент
        this.$emit("create-pay-method", true); // Эмитируем событие с выбранным методом оплаты в родительский компонент
    },
    methods: {
        selectOption(item) {
            this.commissionText = item.commission;
            this.selectedOption = item;
            this.$emit("create-pay-method", false); // Эмитируем событие с выбранным методом оплаты в родительский компонент
            this.$emit("selected-pay-method", item); // отправляем выбранный метод оплаты в родительский компонент
        },
    },
};
</script>

<style></style>
