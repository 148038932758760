<template>
    <div :class="{ filter__chekbox: true, chekbox: true, active: isCheked }" @click="handleCheckboxClick">
        <div class="chekbox__box"></div>
        <slot></slot>
        <div class="chekbox__title" v-if="title">{{ title }}</div>
    </div>
</template>

<script>
export default {
    name: "ChekboxBtn",
    props: {
        title: String,
        filterId: Number,
        filterValueId: Number,
        isChekedDefault: Boolean,
    },
    data() {
        return {
            isCheked: false,
        };
    },
    created() {
        this.isCheked = this.wasCheckedPreviously();
        if (this.isChekedDefault) {
            this.isCheked = true;
        }
    },
    methods: {
        handleCheckboxClick() {
            this.isCheked = !this.isCheked;
            this.$emit("change", this.isCheked);
        },
        wasCheckedPreviously() {
            const filters = this.$route.query.filters ? JSON.parse(this.$route.query.filters) : {};
            return filters[this.filterId] && filters[this.filterId].includes(this.filterValueId);
        },
    },
    watch: {
        "$route.params.categoryId": function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.isCheked = false;
            }
        },
    },
};
</script>

<style></style>
