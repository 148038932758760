<template>
    <div class="sizes" v-if="filteredVariants.length > 0">
        <div class="sizes__title">{{ title }}, {{ optionLabel }}</div>
        <div class="sizes__items">
            <div class="sizes__item" v-for="(variant, index) in filteredVariants" :key="index" :class="{ active: activeIndex === index }" @click="selectedVariants({ variant_id: variant.id, product_id: product_id, index: index, price: variant.price, old_price: variant.old_price, price_origin: variant.price_origin })">
                <div v-for="optionValue in variant.option_values" :key="optionValue.id">
                    <span v-if="optionValue.value !== null">{{ optionValue.value }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VariantsComponent",
    props: {
        title: String,
        variants: Array,
        variant_id: Number,
        product_id: Number,
    },
    data() {
        return {
            activeIndex: 0,
            name: "",
        };
    },
    computed: {
        filteredVariants() {
            return this.variants.filter((variant) => variant.option_values.some((optionValue) => optionValue.value !== null));
        },
        optionLabel() {
            const firstVariant = this.filteredVariants[0];
            if (!firstVariant) return "";

            const firstOptionValueWithNonNullValue = firstVariant.option_values.find((optionValue) => optionValue.value !== null);
            return firstOptionValueWithNonNullValue ? firstOptionValueWithNonNullValue.option.label : "";
        },
    },
    mounted() {
        if (this.filteredVariants.length > 0) {
            this.activeIndex = 0;
            this.selectedVariants({
                variant_id: this.filteredVariants[0].id,
                product_id: this.product_id,
                index: 0,
                price: this.filteredVariants[0].price,
                old_price: this.filteredVariants[0].old_price,
                price_origin: this.filteredVariants[0].price_origin,
            });
        }
    },
    methods: {
        selectedVariants({ title, variant_id, product_id, index, price, old_price, price_origin }) {
            this.activeIndex = index;
            this.$emit("selectedVariants", { title, product_id, variant_id, price, old_price, price_origin });
        },

        getOptionLabel(optionValues) {
            return optionValues.option.name;
        },
    },
};
</script>
