<template>
    <main class="page">
        <section class="notfoundpage">
            <div class="notfoundpage__container">
                <div class="notfoundpage__wrapper">
                    <h1>404</h1>
                    <p>Сторінку не знайдено =(</p>
                    <a href="/" class="thanks__button button"> Перейти на головну </a>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import { useHead } from "@vueuse/head";

export default {
    name: "NotFoundPage",
    setup() {
        useHead({
            title: "404 – Le-perle",
            meta: [
                {
                    name: "robots",
                    content: "noindex",
                },
            ],
        });
    },
};
</script>
