<template>
    <main class="page">
        <section class="contacts">
            <div class="contacts__container">
                <h1></h1>
                <div class="contacts__row">
                    <!-- <div class="contacts__col">
                        <img src="https://silvertouch.co.ua/assets/img/contacts.jpg" alt="contacts.jpg" width="300" />
                    </div> -->
                    <div class="contacts__col">
                        <div>
                            <h2>Служба турботи за клієнтами</h2>
                        </div>
                        <div>
                            <p><b>Телефон:</b> <a href="tel:380937563496">+38 (093) 756 34 96</a> (з 10:00 - 18:30)</p>

                            <p><b>Email:</b> <a href="mailto:hello.le.perle.ua@gmail.com">le.perle.ua@gmail.com</a></p>
                        </div>
                        <div>
                            <p>У випадку термінового питання ви також можете звʼязатись із менеджером у нашому інстаграм магазині з 9:00 до 22:00 (Пн-Нд)</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script></script>

<style>
.contacts {
    padding: 50px 0;
}

.contacts__row h2 {
    font-size: 30px;
    font-weight: 700;
}

.contacts__row p {
    font-size: 18px;
    margin-bottom: 10px;
}

.contacts__row {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 50px;
}

.contacts__container {
    max-width: 800px;
}

.contacts__col {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

@media (max-width: 768px) {
    .contacts__row {
        flex-direction: column;
        row-gap: 20px;
    }

    .contacts__row h2 {
        font-size: 24px;
    }

    .contacts__row p {
        font-size: 16px;
    }
}
</style>
