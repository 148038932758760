<template>
    <section class="product-block" v-if="products && products.length > 0">
        <div class="product-block__container">
            <div class="product-block__body">
                <div class="product-block__top top-block">
                    <router-link :to="{ name: 'Catalog', params: { categoryId: slug ?? '0' } }" class="top-block__title title">{{ title }}</router-link>
                    <div class="product-block__navigation navigation navigation_slider">
                        <button :class="{ navigation__btn: true, 'btn-prev': true, [`prev-${id}`]: true }" @click="prevSlide"></button>
                        <button :class="{ navigation__btn: true, 'btn-next': true, [`next-${id}`]: true }" @click="nextSlide"></button>
                    </div>
                </div>
                <swiper
                    class="product-slider"
                    :modules="modules"
                    :observer="true"
                    :observeParents="true"
                    :speed="300"
                    :scrollbar="{
                        draggable: true,
                    }"
                    :mousewheel="{
                        forceToAxis: true,
                    }"
                    :navigation="{
                        prevEl: `.product-block__navigation .prev-${id}`,
                        nextEl: `.product-block__navigation .next-${id}`,
                    }"
                    :breakpoints="swiperOptions.breakpoints"
                >
                    <swiper-slide v-for="(product, index) in products" :key="index"><ProductCart :product="product" /></swiper-slide>
                </swiper>
            </div>
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Scrollbar, Navigation, Mousewheel } from "swiper";
import ProductCart from "./ProductCart.vue";
import axios from "axios"; // Импорт axios, если он еще не импортирован
import "swiper/css";

export default {
    components: {
        Swiper,
        SwiperSlide,
        ProductCart,
    },
    props: {
        id: {
            type: Number,
        },
        title: {
            type: String,
        },
        slug: {
            type: String,
        },
        categoryId: {
            type: Number,
            required: true,
        },
        products: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            localProducts: [...this.products], // Создаем копию products
            apiUrl: process.env.VUE_APP_PROD_API_URL, // Убедитесь, что у вас настроен apiUrl
            swiperOptions: {
                breakpoints: {
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 15,
                    },
                    767: {
                        slidesPerView: 2.5,
                        spaceBetween: 25,
                    },
                    991: {
                        slidesPerView: 3,
                        spaceBetween: 35,
                    },
                    1270: {
                        slidesPerView: 4,
                        spaceBetween: 50,
                    },
                },
            },
        };
    },
    watch: {
        products: {
            immediate: true,
            handler(newVal) {
                this.localProducts = [...newVal]; // Обновляем локальную копию, когда меняется props.products
            },
        },
        id: {
            immediate: true,
            handler(newVal) {
                if (this.localProducts.length === 0) {
                    this.loadProducts(newVal);
                }
            },
        },
    },
    mounted() {
        if (this.localProducts.length === 0) {
            this.loadProducts(this.id);
        }
    },
    setup() {
        return {
            modules: [Scrollbar, Navigation, Mousewheel],
        };
    },
    methods: {
        async loadProducts(id) {
            try {
                const res = await axios.get(this.apiUrl + "home/products/" + id, {});
                this.localProducts = res.data.data;
            } catch (error) {
                console.error(error);
            }
        },
    },
};
</script>

<style></style>
