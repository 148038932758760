<template>
    <div class="clasp" v-if="clasp.length > 0 && clasp[0] != null && clasp.some((clap) => clap !== '')">
        <div class="clasp__title">{{ title }}</div>
        <div class="clasp__items">
            <div class="clasp__item" v-for="(item, index) in clasp" :key="index" :class="{ active: activeIndex === index }" @click="selectClap(index)">
                {{ item }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "claspProduct",
    props: {
        title: String,
        clasp: Array,
        selectedClasp: String, // Новый пропс для выбранной застежки
    },
    data() {
        return {
            activeIndex: 0,
        };
    },
    watch: {
        selectedClasp: {
            immediate: true,
            handler(newClasp) {
                if (newClasp) {
                    this.activeIndex = this.clasp.indexOf(newClasp);
                }
            },
        },
    },
    methods: {
        selectClap(index) {
            this.activeIndex = index;
            this.$emit("selected", this.clasp[index]);
        },
    },
};
</script>

<style></style>
