import axios from "axios";

export const cart = {
    state: () => ({
        cartCount: 1,
        cartItems: JSON.parse(localStorage.getItem("cart")),
        apiUrl: process.env.VUE_APP_PROD_API_URL,
        productPackage: [],
        productPackageIds: [],
    }),
    getters: {
        changeCartCount(state) {
            return state.cartCount + 11;
        },
        totalPriceCart(state) {
            const total = state.cartItems.reduce((total, item) => {
                let price = item.variant.price ?? item.product.price;
                return total + price * item.quantity;
            }, 0);
            return total;
        },
        getProductPackage(state) {
            return state.productPackage;
        },
        getProductPackageIds(state) {
            return state.productPackageIds;
        },
    },
    mutations: {
        updateCartItems(state, newCartItems) {
            state.cartItems = newCartItems;
        },
        setProductPackage(state, productPackage) {
            state.productPackage = productPackage;
        },
        addProductPackageIds(state, productPackageIds) {
            state.productPackageIds = [...new Set([...state.productPackageIds, ...productPackageIds])];
        },
    },
    actions: {
        fetchCartFromLocalStorage({ commit }) {
            let cart = localStorage.getItem("cart");
            if (cart) {
                cart = JSON.parse(cart);
                commit("updateCartItems", cart);
            }
        },
        updateCart({ commit }, newCartItems) {
            // Обновление localStorage
            localStorage.setItem("cart", JSON.stringify(newCartItems));
            // Обновление Vuex store
            commit("updateCartItems", newCartItems);
        },
        async fetchProductPackage({ commit, state }) {
            try {
                const response = await axios.get(state.apiUrl + `products/tags/package`);
                if (response.data) {
                    commit("setProductPackage", response.data.data);
                    commit(
                        "addProductPackageIds",
                        response.data.data.map((product) => product.id)
                    );
                }
            } catch (error) {
                console.error("Ошибка при получении данных о продукте:", error);
            }
        },

        async fetchProductsTagsNoPromo({ commit, state }) {
            try {
                const response = await axios.get(state.apiUrl + `products/tags/no_promotion?filter=ids`);
                if (response.data) {
                    commit(
                        "addProductPackageIds",
                        response.data.data.map((product) => product.id)
                    );
                }
            } catch (error) {
                console.error("Ошибка при получении данных о продукте:", error);
            }
        },
    },
};
