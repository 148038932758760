import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/router";
import axios from "axios";
// import { Cookies } from "vue3-cookies";

import { defaulLocale } from "./i18n";
import { languages } from "./i18n";
import { createI18n, useI18n } from "vue-i18n";
import store from "./store";
import VueLazyload from "vue-lazyload";
import { createHead } from "@vueuse/head";
import cookiePlugin from "./plugin/cookiePlugin";
import eventidGenerate from "./plugin/eventidGenerate";
import toFixed from "./plugin/toFixed";
import globalProperties from './plugin/globalProperties';

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

import spinerImage from "./assets/img/giphy.gif";
import './registerServiceWorker'

const messages = Object.assign(languages);
const i18n = createI18n({
    legacy: false,
    locale: defaulLocale,
    fallbackLocale: "ru",
    messages,
});

const app = createApp(App, {
    setup() {
        const { t } = useI18n();
        return { t };
    },
});

const head = createHead();

app.use(VueLazyload, {
    preLoad: 1.3,
    error: "path/to/error/image",
    loading: spinerImage,
    attempt: 1,
});

const onlyCountries = [
    'UA', // Україна
    'AT', // Австрія
    'BE', // Бельгія
    'GB', // Велика Британія
    'DE', // Німеччина
    'DK', // Данія
    'ES', // Іспанія
    'IT', // Італія
    'IL', // Ізраїль
    'NL', // Нідерланди
    'NO', // Норвегія
    'PL', // Польща
    'PT', // Португалія
    'FR', // Франція
    'CH', // Швейцарія
    'SE', // Швеція
    'EE', // Естонія
    'LV', // Латвія
    'LT', // Литва
    'FI', // Фінляндія
    'CZ', // Чеська Республіка
    'SK', // Словаччина
    'HU', // Угорщина
    'SI', // Словенія
    'HR', // Хорватія
    'BA', // Боснія і Герцеговина
    'RS', // Сербія
    'ME', // Чорногорія
    'MK', // Македонія
    'AL', // Албанія
    'GR', // Греція
    'BG', // Болгарія
    'RO', // Румунія
    'MD', // Молдова
    'AD', // Андорра
    'MC', // Монако
    'LI', // Ліхтенштейн
    'SM', // Сан-Марино
    'VA', // Ватикан
    'IS', // Ісландія
    'MT', // Мальта
    'CY', // Кіпр
    'TR', // Туреччина
    'CA', // Канада
    'US', // Сполучені Штати Америки
    'MX', // Мексика
    'GT', // Гватемала
    'BZ', // Беліз
    'HN', // Гондурас
    'SV', // Сальвадор
    'NI', // Нікарагуа
    'CR', // Коста-Ріка
    'PA', // Панама
    'CO', // Колумбія
    'VE', // Венесуела
    'GY', // Гаяна
    'SR', // Суринам
    'EC', // Еквадор
    'PE', // Перу
    'BO', // Болівія
    'BR', // Бразилія
    'PY', // Парагвай
    'CL', // Чилі
    'AR', // Аргентина
    'AU', // Австралія
    'AE'  // Об'єднані Арабські Емірати
];


const globalOptions = {
    mode: "international",
    onlyCountries: onlyCountries,
    ignoredCountries: ['RU']
};


app.use(VueLazyload);
app.use(i18n);
app.use(store);
app.use(router);
app.use(head);
app.use(cookiePlugin);
app.use(eventidGenerate);
app.use(toFixed);
app.use(globalProperties);
app.use(VueTelInput, globalOptions);

store.dispatch("global/checkAndSetIdentifier");

app.config.globalProperties.axios = axios;



// // Глобальный обработчик ошибок Vue
// app.config.errorHandler = (err, vm, info) => {
//     const errorDetails = {
//         error: err.toString(),
//         component: vm ? vm.$options.name : '',
//         info
//     };

//     // Здесь можно отправить данные об ошибке на сервер
//     console.error('Vue error:', errorDetails);
//     fetch('https://a.sofard.dev/leperle/frontend/index.php', {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(errorDetails),
//     }).catch((error) => console.error('Ошибка при отправке отчета об ошибке:', error));
// };

// // Обработчик для неперехваченных ошибок JavaScript
// window.addEventListener('error', (event) => {

//     if (!event.lineno && !event.colno && event.message === "Script error.") {
//         // Эту ошибку не отправляем на сервер.
//         return;
//     }

//     const errorDetails = {
//         message: event.message,
//         filename: event.filename,
//         lineno: event.lineno,
//         colno: event.colno,
//         error: event.error ? event.error.toString() : '',
//     };

//     // Отправка данных об ошибке на сервер
//     console.error('JS error:', errorDetails);
//     fetch('https://a.sofard.dev/leperle/frontend/index.php', {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(errorDetails),
//     }).catch((error) => console.error('Ошибка при отправке отчета об ошибке:', error));
// });

// // Для отлавливания необработанных Promise rejection
// window.addEventListener('unhandledrejection', (event) => {
//     const errorDetails = {
//         error: event.reason ? event.reason.toString() : 'Unhandled promise rejection',
//     };

//     // Отправка данных об ошибке на сервер
//     console.error('Promise rejection:', errorDetails);
//     fetch('https://a.sofard.dev/leperle/frontend/index.php', {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(errorDetails),
//     }).catch((error) => console.error('Ошибка при отправке отчета об ошибке:', error));
// });


app.mount("#app");
