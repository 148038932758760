<template>
    <div>
        <div class="order__chekboxs">
            <div v-for="item in tabs" :key="item.tab" :class="{ order__chekbox: true, 'chekbox-order': true, active: activeTab === item.tab }" @click="selectOption(item.tab)">
                <div class="chekbox-order__top">
                    <img :src="item.image" style="max-width: 56px" alt="" />
                </div>
                <div class="chekbox-order__text" v-html="item.text"></div>
            </div>
        </div>
    </div>
    <div data-tabs-body class="tabs-order__content">
        <div class="tabs-order__body" v-show="activeTab === 'tab1'">
            <div class="city-select">
                <!-- Выбор города -->
                <v-select class="form form-v-select form-v-select-np" v-model="selectedCity" :options="cities" @search="fetchCities" size="10" label="Present" placeholder="Оберіть місто">
                    <template #no-options>
                        <div class="no-options">Введіть місто для пошуку</div>
                    </template>
                </v-select>
                <input type="text" class="input form-hidden-field" style="hidden: 0; height: 0; padding: 0; margin: 0; border: none" id="city" v-model="cityRef" name="city" :required="activeTab == 'tab1'" readonly />
                <input type="text" class="input form-hidden-field" id="city_name" v-model="cityName" name="city_name" placeholder="Оберіть місто" @click="showDeliveryCityPopup = true" readonly />
            </div>

            <div class="city-select">
                <!-- Выбор отделения -->
                <v-select class="form form-v-select form-v-select-np" name="department" v-model="selectedDepartment" :options="departments" @search="fetchDepartments" label="Description" placeholder="Оберіть відділення або поштомат">
                    <template #no-options>
                        <div class="no-options">Введіть адресу або номер відділення для пошуку</div>
                    </template></v-select
                >
                <input type="text" class="input form-hidden-field" style="hidden: 0; height: 0; padding: 0; margin: 0; border: none" v-model="cityDepartamentRef" name="department" :required="activeTab == 'tab1'" readonly />
                <input type="text" class="input form-hidden-field" v-model="cityDepartamentName" name="department_name" placeholder="Оберіть відділення або поштомат" @click="showDeliveryDepartamentPopup = true" readonly />
            </div>
        </div>
        <div class="tabs-order__body" v-show="activeTab === 'tab3'">
            <div class="order__col">
                <div class="order__row">
                    <input :required="activeTab === 'tab3'" v-model="expressDeliveryCity" autocomplete="off" type="text" name="express_delivery_city" placeholder="Місто" class="input" />
                </div>
                <div class="order__row">
                    <input :required="activeTab === 'tab3'" v-model="expressDeliveryAddress" autocomplete="off" type="text" name="express_delivery_address" placeholder="Адреса" class="input" />
                </div>
            </div>
        </div>
        <div class="tabs-order__body" v-show="activeTab === 'tab4'">
            <div class="order__col">
                <div class="order__row">
                    <div>
                        <span>{{ firstName }}</span>
                        <span style="margin: 0 2px"></span>
                        <span>{{ lastName }}</span>
                    </div>
                </div>
                <div class="order__row">
                    <input :required="activeTab === 'tab4'" v-model="fieldsurname" class="input" name="surname" type="text" placeholder="По-батькові *" />
                </div>
                <div class="order__row">
                    <input :required="activeTab === 'tab4'" v-model="urkposhtaDeliveryCities" autocomplete="off" type="text" name="urkposhta_delivery_cities" placeholder="Місто *" class="input" />
                </div>
                <div class="order__row">
                    <input :required="activeTab === 'tab4'" v-model="urkposhtaDeliveryIndex" autocomplete="off" type="text" name="urkposhta_delivery_index" placeholder="Поштовий індекс *" class="input" />
                </div>
                <div class="order__row">
                    <input :required="activeTab === 'tab4'" v-model="urkposhtaDeliveryDepartment" autocomplete="off" type="text" name="urkposhta_delivery_department" placeholder="Адреса *" class="input" />
                </div>
            </div>
        </div>
        <div class="tabs-order__body" v-show="activeTab === 'tab5'">
            <div class="order__col">
                <div class="order__row">
                    <div>
                        <span>{{ firstName }}</span>
                        <span style="margin: 0 2px"></span>
                        <span>{{ lastName }}</span>
                    </div>
                </div>
                <div class="order__row">
                    <input :required="activeTab === 'tab5'" v-model="fieldsurname" class="input" name="surname" type="text" placeholder="По-батькові *" />
                </div>
                <div class="order__row">
                    <!-- <input :required="activeTab === 'tab5'" v-model="internationalDeliveryCities" autocomplete="off" type="text" name="international_delivery_counry" placeholder="Країна *" class="input" /> -->
                    <v-select :required="activeTab === 'tab5'" class="form form-v-select input" name="department" v-model="selectedCounty" :options="countryOptions" label="Description" placeholder="Країна *">
                        <template #no-options>
                            <div class="no-options">Оберіть країну доставки</div>
                        </template></v-select
                    >
                    <input type="text" class="input form-hidden-field" id="country" v-model="countryName" name="country" placeholder="Оберіть країну *" @click="showInternationalDelivery = true" :required="activeTab == 'tab5'" readonly />
                </div>
                <div class="order__row">
                    <input :required="activeTab === 'tab5'" v-model="internationalDeliveryCities" autocomplete="off" type="text" name="international_delivery_cities" placeholder="Місто *" class="input" />
                </div>
                <div class="order__row">
                    <input :required="activeTab === 'tab5'" v-model="internationalDeliveryIndex" autocomplete="off" type="text" name="international_delivery_index" placeholder="Поштовий індекс *" class="input" />
                </div>
                <div class="order__row">
                    <input :required="activeTab === 'tab5'" v-model="internationalDeliveryDepartment" autocomplete="off" type="text" name="international_delivery_department" placeholder="Адреса *" class="input" />
                </div>
            </div>
        </div>
    </div>
    <div class="delivery-popup" v-show="showDeliveryCityPopup">
        <div class="delivery-popup-bg" @click="showDeliveryCityPopup = false"></div>
        <div class="delivery-popup__wrapper">
            <input class="input delivery-popup__wrapper-input" type="text" placeholder="Пошук" v-model="selectedCityMob" :options="cities" @input="fetchCitiesMob('input')" />

            <ul class="delivery-popup__list">
                <li v-for="city in cities" :key="city.id" :data-name="city.Present" :data-ref="city.DeliveryCity" @click="handleCitySelect(city)">
                    {{ city.Present }}
                </li>
            </ul>
        </div>
    </div>
    <div class="delivery-popup" v-show="showDeliveryDepartamentPopup">
        <div class="delivery-popup-bg" @click="showDeliveryDepartamentPopup = false"></div>
        <div class="delivery-popup__wrapper">
            <input class="input delivery-popup__wrapper-input" type="text" placeholder="Пошук" v-model="selectedDepartmentMob" :options="cities" @input="fetchDepartmentsMob()" />

            <ul class="delivery-popup__list">
                <li v-for="department in departments" :key="department.id" :data-name="department.Description" :data-ref="department.Ref" @click="handleCityDepartamentSelect(department)">
                    {{ department.Description }}
                </li>
            </ul>
        </div>
    </div>
    <div class="delivery-popup" v-show="showInternationalDelivery">
        <div class="delivery-popup-bg" @click="showInternationalDelivery = false"></div>
        <div class="delivery-popup__wrapper">
            <input class="input delivery-popup__wrapper-input" type="text" placeholder="Пошук" v-model="searchQueryCounty" />

            <ul class="delivery-popup__list">
                <li v-for="country in filterCountries()" :key="country" @click="handleCountySelect(country)">
                    {{ country }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import countries from "@/assets/countries.json";

export default {
    components: {
        "v-select": vSelect,
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_PROD_API_URL,
            selectedCity: "",
            cityName: "", // инпут названия города
            cityRef: "", // инпут ID города
            cityDepartamentName: "", // инпут названия отделения
            cityDepartamentRef: "", // инпут ID отделений
            selectedCityOption: "", // Массив данных city
            selectedCitRefOption: "", // Массив данных city ref
            selectedDepartment: null,
            selectedDepartmentMob: null,
            selectedCounty: "",
            cities: [],
            departments: [],
            activeTab: "tab1", // активная вкладка
            tabs: [
                { tab: "tab1", text: "Нова пошта", image: require("../assets/img/order/novaposhta.svg") },
                { tab: "tab3", text: "Кур’єр<br> нової пошти", image: require("../assets/img/order/02.svg") },
                { tab: "tab4", text: "Укр Пошта", image: require("../assets/img/order/ukrpochta.svg") },
                { tab: "tab5", text: "Міжнародна <br>доставка", image: require("../assets/img/order/international_delivery.svg") },
            ],
            expressDeliveryCity: "",
            expressDeliveryAddress: "",
            urkposhtaDeliveryCities: "",
            urkposhtaDeliveryIndex: "",
            urkposhtaDeliveryDepartment: "",
            fieldsurname: "",
            showDeliveryCityPopup: false,
            showDeliveryDepartamentPopup: false,
            showInternationalDelivery: false,
            countryOptions: countries,
            searchQueryCounty: "",
            // Международная доставка
            internationalCountryName: "",
            internationalDeliveryCities: "",
            internationalDeliveryIndex: "",
            internationalDeliveryDepartment: "",
        };
    },
    mounted() {
        // Вызов метода с пустой строкой или начальным значением для поиска
        this.fetchCitiesMob();
        this.emitDeliveryData();

        // document.addEventListener("click", this.handleClickOutside);

        // document.addEventListener("click", this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener("click", this.handleClickOutside);
    },
    props: {
        firstName: String,
        lastName: String,
    },

    watch: {
        // Нова пошта
        selectedCity: [
            function (newVal) {
                this.cityName = newVal.Present;
                this.cityRef = newVal.DeliveryCity;
                this.fetchDepartments(null);
            },
        ],

        selectedDepartment: [
            function (newVal) {
                this.cityDepartamentName = newVal.Description;
                this.cityDepartamentRef = newVal.Ref;
            },
        ],
        cityRef: "emitDeliveryData",
        cityDepartamentRef: "emitDeliveryData",
        fieldsurname: "emitDeliveryData", // Отчество

        // Курьерская доставка
        activeTab: "emitDeliveryData",
        expressDeliveryCity: "emitDeliveryData",
        expressDeliveryAddress: "emitDeliveryData",
        // Укрпошта
        urkposhtaDeliveryCities: "emitDeliveryData",
        urkposhtaDeliveryIndex: "emitDeliveryData",
        urkposhtaDeliveryDepartment: "emitDeliveryData",

        // Международная доставка
        selectedCounty: [
            function (newVal) {
                this.countryName = newVal;
                this.internationalCountryName = newVal;
            },
        ],
        internationalCountryName: "emitDeliveryData",
        internationalDeliveryCities: "emitDeliveryData",
        internationalDeliveryIndex: "emitDeliveryData",
        internationalDeliveryDepartment: "emitDeliveryData",
    },

    methods: {
        handleSubmit() {
            if ((this.activeTab === "tab1" && !this.selectedCity) || (this.activeTab === "tab3" && !this.selectedDepartment)) {
                alert("Будь ласка, заповніть всі обов'язкові поля");
                return;
            }

            this.$el.querySelector("form").submit();
        },

        changeTab(tabName) {
            this.activeTab = tabName;
        },
        async fetchCities(search) {
            if (search.length > 0) {
                const response = await axios.get(this.apiUrl + `np/cities/${search}`);
                if (response.data.success) {
                    this.cities = response.data.result[0].Addresses;
                }
            }
        },

        async fetchCitiesMob() {
            const search = this.selectedCityMob ? this.selectedCityMob : "м. ";
            // if (search.length > 0) {

            const response = await axios.get(this.apiUrl + `np/cities/${search}`);
            if (response.data.success) {
                this.cities = response.data.result[0].Addresses;
            }
            // }
        },
        async fetchDepartments(search) {
            if (this.selectedCity) {
                const cityRef = this.selectedCity.DeliveryCity;

                const response = await axios.get(this.apiUrl + `np/warehouses/${cityRef}?search=${search}`);
                this.departments = response.data;
            }
        },
        async fetchDepartmentsMob() {
            // if ((this.selectedDepartmentMob && this.selectedDepartmentMob.length > 1 && this.cityRef) || (this.cityRef && load)) {
            if (this.cityRef) {
                const response = await axios.get(this.apiUrl + `np/warehouses/${this.cityRef}?search=${this.selectedDepartmentMob}`);

                this.departments = response.data;
            }
        },
        filterCountries() {
            return this.countryOptions.filter(
                (country) =>
                    // Убедимся, что country определена и является строкой перед вызовом toLowerCase
                    country && typeof country === "string" && country.toLowerCase().includes(this.searchQueryCounty.toLowerCase())
            );
        },
        selectOption(tabName) {
            this.activeTab = tabName;
        },

        emitDeliveryData() {
            const deliveryData = {
                activeTab: this.activeTab,
            };

            if (this.activeTab === "tab1") {
                deliveryData.selectedCity = this.selectedCity !== "" ? this.selectedCity : this.selectedCityOption;
                deliveryData.selectedDepartment = this.selectedDepartment !== null ? this.selectedDepartment : this.selectedCitRefOption;
            }

            if (this.activeTab === "tab3") {
                deliveryData.expressDeliveryCity = this.expressDeliveryCity;
                deliveryData.expressDeliveryAddress = this.expressDeliveryAddress;
            }

            if (this.activeTab === "tab4") {
                deliveryData.urkposhtaDeliveryCities = this.urkposhtaDeliveryCities;
                deliveryData.urkposhtaDeliveryIndex = this.urkposhtaDeliveryIndex;
                deliveryData.urkposhtaDeliveryDepartment = this.urkposhtaDeliveryDepartment;
                deliveryData.fieldsurname = this.fieldsurname;
            }

            if (this.activeTab === "tab5") {
                deliveryData.internationalCountryName = this.internationalCountryName;
                deliveryData.internationalDeliveryCities = this.internationalDeliveryCities;
                deliveryData.internationalDeliveryIndex = this.internationalDeliveryIndex;
                deliveryData.internationalDeliveryDepartment = this.internationalDeliveryDepartment;
                deliveryData.fieldsurname = this.fieldsurname;
            }

            this.$emit("delivery-data", deliveryData);
        },
        closePopup() {
            this.showDeliveryCityPopup = false;
            // Удаляем слушатель событий
            document.removeEventListener("click", this.handleClickOutside);
        },
        openPopup() {
            this.showDeliveryCityPopup = true;
            // Добавляем слушатель событий
            this.$nextTick(() => {
                document.addEventListener("click", this.handleClickOutside);
            });
        },
        handleClickOutside(event) {
            const popup = this.$refs.popup;
            if (popup && !popup.contains(event.target)) {
                this.closePopup();
            }
        },
        handleCitySelect(city) {
            this.selectedCityOption = city;
            if (city) {
                this.cityName = city.Present;
                this.cityRef = city.DeliveryCity;
            }
            this.fetchDepartmentsMob();
            this.showDeliveryCityPopup = false;
        },
        handleCityDepartamentSelect(department) {
            this.selectedCitRefOption = department;
            if (department) {
                this.cityDepartamentName = department.Description;
                this.cityDepartamentRef = department.Ref;
            }
            this.showDeliveryDepartamentPopup = false;
        },
        handleCountySelect(country) {
            this.countryName = country;
            this.internationalCountryName = country;

            this.showInternationalDelivery = false;
        },
    },
};
</script>

<style>
@import "vue-select/dist/vue-select.css";
</style>

<style scoped>
.city-select .v-select {
    /* color: inherit;
    text-align: center;
    background-color: #fff;
    cursor: pointer;
    width: 100%;
    border: 1px solid #aaa;
    border-radius: 5px; */
}
.city-select {
    margin-bottom: 10px;
}
.city-select .vs__dropdown-toggle {
    color: inherit;
    text-align: center;
    background-color: #fff;
    cursor: pointer;
    width: 100%;
    border: 1px solid #aaa;
    border-radius: 5px;
}

.city-select ::v-deep .vs__search {
    width: 100%;
    background-color: transparent;
    height: 100%;
    margin: 0;
}

.city-select ::v-deep .vs__actions {
    padding-right: 15px;
}

.city-select ::v-deep .vs__selected-options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-width: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 3.0625rem;
    padding: 0 0.9375rem;
    gap: 0.625rem;
    font-weight: 500;
    line-height: 131.3%;
    color: rgba(0, 0, 0, 0.77);
}

.delivery-popup {
    /* background: rgba(0, 0, 0, 0.4); */
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 150;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.delivery-popup-bg {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 150;
    display: flex;
    flex-direction: column;
    justify-content: end;
    z-index: 1;
}

.delivery-popup__wrapper {
    max-height: 384px;
    height: 100%;
    background: #fff;
    padding: 22px;
    z-index: 50;
    font-size: 14px;
    position: relative;
}
.delivery-popup__wrapper .input {
    border-radius: 6px !important;
    border-color: #00000030;
}

.delivery-popup__select {
    margin: 20px 0;
    width: 100%;
}

/* Для большинства браузеров */
.delivery-popup__select:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

/* Для Firefox */
.delivery-popup__select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

.delivery-popup__wrapper-input {
    font-size: 16px;
}

@media (min-width: 768px) {
    .form-hidden-field {
        height: 1px !important;
        opacity: 0.1;
        padding: 0;
        border: none;
    }

    .city-select {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }
}
@media (max-width: 768px) {
    .city-select ::v-deep .vs__selected-options {
        height: 2.8125rem;
    }

    .form-v-select {
        display: none;
    }
    .form-hidden-field {
        /* margin-bottom: 10px; */
    }

    .delivery-popup__list {
        margin: 20px 0;
        height: 80%;
        width: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        row-gap: 18px;
        font-size: 16px;
    }
}

.city-select ::v-deep .vs__selected {
    display: flex;
    height: 100%;
    align-items: center;
}

.form.form-v-select.input {
    padding: 0;
}
.form.form-v-select.input ::v-deep .vs__dropdown-toggle {
    height: 100% !important;
    padding-left: 20px;
}
</style>
