<template>
    <div v-if="notFound">
        <NotFoundPage />
    </div>
    <div v-if="product && Object.keys(product).length">
        <div class="top-page top-page_empty">
            <div class="top-page__container">
                <div class="top-page__breadcrumbs breadcrumbs">
                    <ul class="breadcrumbs__list">
                        <li class="breadcrumbs__item">
                            <router-link to="/" class="breadcrumbs__link">Le’Perle</router-link>
                        </li>
                        <li class="breadcrumbs__item" v-if="product.value.category">
                            <router-link :to="{ name: 'Catalog', params: { categoryId: product.value.category.slug } }" class="breadcrumbs__link">{{ product.value.category.title.ua }}</router-link>
                        </li>
                        <li class="breadcrumbs__item">
                            <span class="breadcrumbs__current">{{ product.value.name }} </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <section class="product">
            <div class="product__container">
                <div class="product__body">
                    <div class="product__images">
                        <swiper
                            :slides-per-view="1"
                            :modules="modules"
                            :speed="800"
                            :thumbs="{ swiper: thumbsSwiper }"
                            :pagination="{ el: '.main-slider__pagination', clickable: true }"
                            :navigation="{
                                prevEl: '.main-slider__navigation .btn-prev',
                                nextEl: '.main-slider__navigation .btn-next',
                            }"
                            class="product__main-slider main-slider"
                        >
                            <div class="product__main-label" v-if="product.value.price === product.value.price_origin">1+1=3</div>

                            <swiper-slide v-for="media in product.value.downloads" :key="media.id" class="main-slider__slide-ibg">
                                <a data-fancybox="gallery" :href="getImageProduct(media)">
                                    <img :src="getImageProduct(media)" alt="" />
                                </a>
                            </swiper-slide>
                            <swiper-slide v-for="media in product.value.sets" :key="media.id" class="main-slider__slide-ibg">
                                <a data-fancybox="gallery" :href="getImageProduct(media)">
                                    <img :src="getImageProduct(media)" alt="" />
                                </a>
                            </swiper-slide>

                            <div class="main-slider__navigation navigation-product">
                                <button class="navigation-product__btn btn-prev"></button>
                                <button class="navigation-product__btn btn-next"></button>
                            </div>
                            <div class="main-slider__pagination pagination"></div>
                        </swiper>
                        <swiper :slides-per-view="4" :space-between="6" :modules="modules" watch-slides-progress @swiper="setThumbsSwiper" class="product__trumb-slider trumb-slider">
                            <swiper-slide v-for="media in product.value.downloads" :key="media.id" class="trumb-slider__slide-ibg">
                                <img :src="getImageProduct(media)" alt="" />
                            </swiper-slide>
                            <swiper-slide v-for="media in product.value.sets" :key="media.id" class="trumb-slider__slide-ibg">
                                <img :src="getImageProduct(media)" alt="" />
                            </swiper-slide>
                        </swiper>
                    </div>

                    <div class="product__content content-product">
                        <div class="content-product__body">
                            <span class="content-product__is-aviables" style="background: #a8df5c" v-if="product.value.in_stock === 1">В наявності!</span>
                            <span class="content-product__is-aviables" style="background: #fdb642" v-if="product.value.in_stock === 2">Закінчується!</span>
                            <span class="content-product__is-aviables" style="background: #c5c5c5" v-if="product.value.in_stock === 0">Немає у наявності!</span><br v-if="product.value.available_for_order === 1" />
                            <h1 class="content-product__title" v-if="product.value">{{ product.value.name }}</h1>
                            <div class="content-product__material">
                                <span v-for="(material, index) in product.value.material" :key="index"> {{ material.value.ua }}<span v-if="index < product.value.material.length - 1">,</span> </span>
                            </div>
                            <div class="content-product__price">
                                <div class="content-product__price-price price">
                                    <!-- <div class="price__current sale">{{ Math.round(variantPrice * 0.95) }} грн.</div> -->
                                    <div class="price__current sale">{{ Math.round(variantPrice) }} грн.</div>
                                    <div class="price__old" v-if="product.value.old_price && product.value.old_price !== null">{{ Math.round(variantOldPrice) }} <span v-if="variantOldPrice !== null"> грн.</span></div>
                                    <!-- <div class="price__old" v-if="product.value.old_price && product.value.old_price !== null">{{ variantOldPrice }} <span v-if="variantOldPrice !== null"> грн.</span></div> -->
                                </div>

                                <div class="content-product__price-profit" v-if="product.value.old_price && product.value.old_price !== null">
                                    <span>Вигода {{ Math.round(variantOldPrice) - Math.round(variantPrice) }} грн.</span>
                                </div>
                            </div>

                            <div v-if="product.value.engraving" class="content-product__engraving">
                                <div class="content-product__engraving-select">
                                    <v-select v-model="selectedEngraving" :options="product.value.engraving" class="form form-v-select" size="10" label="Engraving" placeholder="Оберіть фразу">
                                        <template #no-options>
                                            <div class="no-options">Немає доступних фраз</div>
                                        </template>
                                    </v-select>
                                </div>

                                <transition name="accordion" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
                                    <div class="content-product__engraving-input" v-if="isVisibleEngraving">
                                        <input v-model="inputEngraving" class="input" type="text" placeholder="Власна назва" />
                                    </div>
                                </transition>
                                <div class="content-product__engraving-btn">
                                    <button v-if="this.isVisibleEngraving" @click="toggleShowEngraving" class="content-product__engraving-button button button_line">
                                        <span>-</span>
                                        Прибрати власний надпис
                                    </button>
                                    <button v-if="!this.isVisibleEngraving" @click="toggleShowEngraving" class="content-product__engraving-button button button_line">
                                        <span>+</span>
                                        Додати свій надпис
                                    </button>
                                </div>
                            </div>
                            <div v-for="set in product.value.sets" :key="set.id">
                                <VariantsComponent :title="set.name" :product_id="set.id" :variant_id="set.id" :variants="set.variants" @selectedVariants="handleSelectedVariant" class="sizes_small content-product__sizes" />
                            </div>

                            <Sizes v-if="!product.value.is_set" class="sizes_small content-product__sizes" :title="sizeLabel" :sizes="getAvailableSizes()" :selectedSize="selectedSize" @selected="selectedSize = $event" />
                            <Lenght v-if="!product.value.is_set" class="sizes_small content-product__sizes" :title="'Довжина (см):'" :lengths="getAvailableLengths()" :category_all="product.value.category_all" :selectedLength="selectedLength" @selected="selectedLength = $event" @showPopupSizes="sizesCategory = $event" />

                            <Clasp v-if="!product.value.is_set" class="content-product__clasp" title="Застібка:" :clasp="getAvailableClasps()" :selectedClasp="selectedClasp" @selected="selectedClasp = $event" />
                            <Colors v-if="!product.value.is_set" class="content-product__colors" :title="'Колір: ' + selectedColor" :colors="getAvailableColors()" v-model="selectedColor" @selected="selectedColor = $event" />

                            <!-- отображение выбранного варианта -->
                            <div v-if="selectedVariant">
                                Вы выбрали:
                                <p>Артикул: {{ selectedVariant.vendor_code }}</p>
                                ...
                            </div>
                            <div class="content-product__amount amount-product">
                                <div class="content-product__subtitle">Кількість:</div>

                                <div class="amount-product__line">
                                    <Counter v-model="quantity" />
                                    <div class="button-fixed-bottom">
                                        <button class="amount-product__button button amount-product__button-main" @click="addToCart">
                                            <span v-if="product.value.available_for_order === 1">
                                                <!-- <img src="../assets/img/pautina.png" alt="" /> -->
                                                Передзамовлення
                                            </span>
                                            <span v-else>
                                                <!-- <img src="../assets/img/pautina.png" alt="" /> -->

                                                Додати в кошик</span
                                            >
                                        </button>
                                    </div>
                                </div>
                                <div class="amount-product__line">
                                    <span v-if="variantVendorCode">код товару: {{ variantVendorCode }}</span>
                                </div>
                            </div>
                        </div>

                        <!-- <button v-if="isMainButtonOutOfView" class="amount-product__button button " @click="addToCart">Додати в кошик</button> -->

                        <div class="content-product__description description-product">
                            <div class="content-product__subtitle">Характеристики:</div>

                            <div class="characteristics-product__items" v-for="characteristic in product.value.characteristics" :key="characteristic.filter_name">
                                <div class="characteristics-product__title">
                                    {{ characteristic.filter_name }}
                                </div>
                                <div class="characteristics-product__text">
                                    <span v-for="value in characteristic.values" :key="value">
                                        {{ value }}
                                    </span>
                                </div>
                            </div>

                            <div class="characteristics-product__pays">
                                <div class="characteristics-product__pay"><img src="../assets/img/footer/apple-pay.svg" alt="" width="60" /></div>
                                <div class="characteristics-product__pay"><img src="../assets/img/footer/google-pay.svg" alt="" width="60" /></div>
                                <div class="characteristics-product__pay"><img src="../assets/img/footer/mastercard.svg" alt="" width="40" /></div>
                                <div class="characteristics-product__pay"><img src="../assets/img/footer/visa.svg" alt="" width="40" /></div>
                                <div class="characteristics-product__pay"><img src="../assets/img/footer/monopay.webp" alt="" width="25" /></div>
                                <div class="characteristics-product__pay"><img src="../assets/img/footer/wayforpay.svg" alt="" width="65" /></div>
                            </div>
                        </div>

                        <div class="content-product__description description-product">
                            <!-- <div v-if="product.value.description !== 'NULL'" class="content-product__subtitle">Опис товару:</div> -->

                            <div class="description-product__text" v-if="product.value.description !== 'NULL'">
                                <!-- <p v-html="product.value.description"></p> -->
                            </div>

                            <div v-for="(item, index) in items" :key="index" class="accordion-item">
                                <button @click="toggle(index)">{{ item.title }}</button>
                                <div v-if="isOpen[index]" class="accordion-content">
                                    {{ item.content }}
                                </div>
                            </div>

                            <div>
                                <div v-for="(item, index) in accordionData" :key="index" class="accordion-item">
                                    <button @click="toggleAccordion(index)">
                                        {{ item.title }}
                                        <span class="_icon-close" :style="isOpen[index] ? 'transform: rotate(0deg);' : ''"></span>
                                    </button>
                                    <transition name="slide-fade">
                                        <div v-if="isOpen[index]" class="accordion-content" v-html="item.content"></div>
                                    </transition>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <ProductsSliderArray :title="'Рекомендовані товари'" :products="product.value.recommendations" id="1" />
        <ProductsSliderArray :title="'Останні переглянуті товари'" :products="viewedProducts" id="2" />
    </div>
    <!-- <div class="popup__sizes">
        <div class="popup__container">123</div>
    </div> -->
    <popup-sizes :show="isPopupSizesOpen" :category="categorySizes" @update:show="isPopupSizesOpen = $event"></popup-sizes>
</template>

<script>
/* global dataLayer */

import { reactive, ref, onMounted, watch, computed, watchEffect, getCurrentInstance } from "vue";
import ProductsSliderArray from "./ProductsSliderArray.vue";
import Sizes from "./Sizes.vue";
import Lenght from "./Lenght.vue";
import Clasp from "./Clasp.vue";
import Colors from "./Colors.vue";
import PopupSizes from "./PopupSizes.vue";
import VariantsComponent from "./VariantsComponent.vue";
import Counter from "./Counter.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Thumbs, Navigation, Pagination } from "swiper";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import { useStore } from "vuex";
import vSelect from "vue-select";
import { useHead } from "@vueuse/head";
import NotFoundPage from "../components/404.vue";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

// Import Swiper styles
import "swiper/css";

export default {
    components: {
        ProductsSliderArray,
        Sizes,
        Clasp,
        Colors,
        Counter,
        VariantsComponent,
        Lenght,
        Swiper,
        SwiperSlide,
        "v-select": vSelect,
        NotFoundPage,
        PopupSizes,
    },
    data() {
        return { isVisibleEngraving: false };
    },
    setup() {
        const store = useStore();

        const product = reactive({});

        const selectedEngraving = ref(null);
        const inputEngraving = ref(null);

        const thumbsSwiper = ref(null);

        const priceStack = reactive({}); // Для хранения цен по variant_id

        const selectedVariants = ref(null);
        const selectedVariantsArray = ref([]);
        const router = useRouter(); // Подключаем роутер

        const categorySizes = ref(0);
        const isPopupSizesOpen = ref(false);
        const sizesCategory = ref(null);
        const selectedSize = ref(null);
        const selectedLength = ref(null);
        const selectedClasp = ref(null);
        const selectedColor = ref(null);

        const selectedVariant = ref(null);
        const variantPrice = ref(0);
        const variantOldPrice = ref(0);
        const variantVendorCode = ref(0);
        const notFound = ref(0);

        const quantity = ref(1);
        const sizeLabel = ref(""); // Инициализируйте переменную sizeLabel

        const isOpen = ref([]); // Добавьте это в ваш setup()
        const totalPrice = computed(() => store.getters.totalPriceCart);

        const isMainButtonOutOfView = ref(false); // добавьте эту строку

        const component = getCurrentInstance(); // Получение доступа к экземпляру компонента
        const apiUrl = process.env.VUE_APP_PROD_API_URL;

        const getCookie = component.appContext.config.globalProperties.$getCookie;
        const getEventId = component.appContext.config.globalProperties.$getEventId;
        const event_id = getEventId();
        const external_id = localStorage.getItem("external_id");

        const uploadUrl = ref(component.appContext.config.globalProperties.$uploadUrl);

        const accordionData = computed(() => {
            let items = [
                { title: "Чи тьмяніють прикраси?", content: '<div class="description-product__text">При правильній експулатації прикраси не тьмяніють, але якщо така ситуація сталась за 14 днів експлуатації то ми зробимо вам обмін товару за наш рахунок:)</div>' },
                { title: "Чи можна мочити прикраси?", content: '<div class="description-product__text">Можна мочити: прикраси з перлин, або іншого каміння на срібній застібці.<br><br>Не рекомендується мочити прикраси з срібним покриттям та позолотою так як це може вплинути на їх зовнішній вигляд.</div>' },
                { title: "Доставка та оплата", content: '<div class="description-product__text"><div class="description-product__line"><div class="description-product__img"><img src="../assets/img/novaposhta.png" alt="" /></div><div class="description-product__line-body"><span>Нова пошта відділення або кур’єр</span><span>від 70 грн</span></div></div><div class="description-product__line"><div class="description-product__img"><img src="../assets/img/ukrposhta.png" alt="" /></div><div class="description-product__line-body"><span>Укр Пошта відділення </span><span style="text-align: end">в Україні від 40 грн<br />в Європу від 250 грн</span></div></div></div> <div class="description-product__text"><p>Накладний платіж з передплатою 150 грн. (додатково оплачується комісія за повернення коштів в розмірі 20 грн і 2% від суми замовлення)</p><p>Оплата за реквізитами ФОП - комісія 0%</p><p>Через платіжну систему MonoPay - комісія 0%</p></div>' },
                { title: "Яка гарантія на вироби?", content: '<div class="description-product__text">На всі прикраси, представлені в інтернет-магазині Le-perle, діє гарантія 1 місяць. <br><br>Якщо при першій же примірці з прикрасою щось сталося, ми забезпечуємо обмін виробів, оплачуючи доставку в обидві сторони. У будь-якому іншому випадку наш майстер повинен оцінити виріб для того, щоб визначити, чи потрапляє поломка під гарантію компанії. <br><br>Якщо прикрасу було пошкоджено через неакуратну носку , ми можемо відремонтувати її якщо це можливо, проте доставка в обидві сторони оплачується клієнтом.</div>' },
                { title: "Чи можу я обміняти товар?", content: '<div class="description-product__text">Протягом 14 днів з моменту покупки Ви маєте можливість здійснити обмін обраного товару на інший. <br><br>Обмін можливий лише в тому випадку, якщо збережено товарний вид прикраси, є упаковка, а також немає слідів експлуатації.</div>' },
                { title: "Коли відправлять моє замовлення?", content: '<div class="description-product__text">Замовлення надсилаються протягом 1-3 днів з моменту підтвердження замовлення.<br><br>Якщо вам необхідна термінова відправка, звʼяжіться з менеджером в чаті на сайті або в Telegram, Viber за номером телефону, також ви можете залишити коментар при оформленні замовлення.</div>' },
            ];

            if (product.value.description && product.value.description.trim() !== "") {
                items.unshift({
                    title: "Опис товару",
                    content: '<div class="description-product__text">' + product.value.description + "</div>",
                });
            }

            return items;
        });

        const toggleAccordion = (index) => {
            isOpen.value[index] = !isOpen.value[index];
        };

        const getAvailableSizes = () => {
            return product.value.variants.reduce((sizes, variant) => {
                const size = variant.option_values.find((option) => option.option.name === "size");

                if (size) {
                    sizeLabel.value = size.option.label; // Присваивание значения sizeLabel
                } else {
                    // Обработка случая, когда опция size не найдена
                    sizeLabel.value = "123"; // Присваивание значения sizeLabel
                }

                if (size && !sizes.includes(size.value)) {
                    sizes.push(size.value);
                }
                return sizes;
            }, []);
        };

        const getAvailableColors = () => {
            return product.value.variants.reduce((colors, variant) => {
                const color = variant.option_values.find((option) => option.option.name === "color");
                if (color && !colors.includes(color.value) && color.value != "-") {
                    colors.push({ color: color.value, color_hex: color.color_hex });
                }
                return colors;
            }, []);
        };

        const hasOption = (optionName) => {
            return product.value.variants.some((variant) => {
                return variant.option_values.find((option) => option.option.name === optionName);
            });
        };

        const getAvailableLengths = () => {
            // if (!selectedSize.value) return [];
            if (!hasOption("size")) return [];

            return product.value.variants.reduce((lengths, variant) => {
                const size = variant.option_values.find((option) => option.option.name === "size");
                const length = variant.option_values.find((option) => option.option.name === "length");

                if (size && size.value === selectedSize.value && length && !lengths.includes(length.value) && length.value != "-") {
                    lengths.push(length.value);
                }
                return lengths;
            }, []);
        };

        const getAvailableClasps = () => {
            // if (!selectedSize.value || !selectedLength.value) return [];
            if (hasOption("size") && !selectedSize.value) return [];
            if (hasOption("length") && !selectedLength.value) return [];

            return product.value.variants.reduce((clasps, variant) => {
                const size = variant.option_values.find((option) => option.option.name === "size");
                const length = variant.option_values.find((option) => option.option.name === "length");
                const clasp = variant.option_values.find((option) => option.option.name === "clasp");

                if (size && size.value === selectedSize.value && length && length.value === selectedLength.value && clasp && !clasps.includes(clasp.value) && clasp.value != "-") {
                    clasps.push(clasp.value);
                }
                return clasps;
            }, []);
        };

        watch(selectedLength, () => {});

        watch(selectedSize, () => {
            const availableLengths = getAvailableLengths();
            if (availableLengths.length > 0) {
                selectedLength.value = availableLengths[0]; // Выбрать первую доступную длину
            }

            // Теперь выберите первую доступную застежку, так как длина тоже изменилась
            const availableClasps = getAvailableClasps();
            if (availableClasps.length > 0) {
                selectedClasp.value = availableClasps[0]; // Выбрать первую доступную застежку
            } else {
                selectedClasp.value = null;
            }

            const availableColors = getAvailableColors();
            if (availableColors.length > 0) {
                selectedColor.value = availableColors[0].color;
            }
        });

        watch(sizesCategory, () => {
            if (sizesCategory.value.categoryId) {
                isPopupSizesOpen.value = true;
                categorySizes.value = sizesCategory.value.categoryId;
            }
        });

        watch(selectedLength, () => {
            const availableClasps = getAvailableClasps();
            if (availableClasps.length > 0) {
                selectedClasp.value = availableClasps[0]; // Выбрать первую доступную застежку
            }

            const availableColors = getAvailableColors();
            if (availableColors.length > 0) {
                selectedColor.value = availableColors[0].color;
            }
        });

        watch(selectedClasp, () => {
            const availableColors = getAvailableColors();
            if (availableColors.length > 0) {
                selectedColor.value = availableColors[0].color;
            }
        });

        watch(
            () => [selectedSize.value, selectedLength.value, selectedClasp.value, selectedColor.value],
            () => {
                selectedVariant.value = product.value.variants.find((variant) => {
                    const size = variant.option_values.find((option) => option.option.name === "size");
                    const length = variant.option_values.find((option) => option.option.name === "length");
                    const clasp = variant.option_values.find((option) => option.option.name === "clasp");
                    const color = variant.option_values.find((option) => option.option.name === "color");

                    return (!selectedSize.value || (size && size.value === selectedSize.value)) && (!selectedLength.value || (length && length.value === selectedLength.value)) && (!selectedClasp.value || (clasp && clasp.value === selectedClasp.value)) && (!selectedColor.value || (color && color.value === selectedColor.value));
                });

                if (selectedVariant.value) {
                    variantPrice.value = selectedVariant.value.price;
                    variantOldPrice.value = selectedVariant.value.old_price;
                    variantVendorCode.value = selectedVariant.value.vendor_code;
                } else {
                    variantPrice.value = product.value.price;
                    variantOldPrice.value = product.value.old_price;
                    variantVendorCode.value = product.value.vendor_code;
                }
            }
        );

        const selectVariantByVendorCode = (vendorCode) => {
            const variant = product.value.variants.find((variant) => variant.vendor_code === vendorCode);
            if (variant) {
                selectedSize.value = getOptionValue(variant, "size");
                selectedLength.value = getOptionValue(variant, "length");
                selectedClasp.value = getOptionValue(variant, "clasp");
                selectedColor.value = getOptionValue(variant, "color");
                selectedVariant.value = variant;
                variantPrice.value = variant.price;
                variantOldPrice.value = variant.old_price;
                variantVendorCode.value = variant.vendor_code;
            }
        };

        const getOptionValue = (variant, optionName) => {
            const option = variant.option_values.find((opt) => opt.option.name === optionName);
            return option ? option.value : null;
        };

        const selectFirstAvailableOptions = () => {
            const availableSizes = getAvailableSizes();
            if (availableSizes.length > 0) {
                selectedSize.value = availableSizes[0]; // Выбрать первый доступный размер

                const availableLengths = getAvailableLengths();
                if (availableLengths.length > 0) {
                    selectedLength.value = availableLengths[0]; // Выбрать первую доступную длину

                    const availableClasps = getAvailableClasps();
                    if (availableClasps.length > 0) {
                        selectedClasp.value = availableClasps[0]; // Выбрать первую доступную застежку
                    }
                }
            }
            const availableColors = getAvailableColors();
            if (availableColors.length > 0) {
                selectedColor.value = availableColors[0].color; // Выбрать первый доступный цвет
            }
        };

        const fbProduct = () => {
            window.fbq("track", "ViewContent", {
                content_category: product.value.category ? product.value.category.title.ua : "",
                content_ids: [product.value.vendor_code],
                content_name: product.value.name,
                content_type: "product",
                external_id: external_id,
                event_id: event_id,
                currency: "UAH",
                source: "le-perle.com.ua",
                value: product.value.price,
            });

            try {
                axios.post(apiUrl + "fbapi", {
                    action: "ViewContent",
                    event_id: event_id,
                    content_category: product.value.category ? product.value.category.title.ua : "",
                    value: product.value.price,
                    content_name: product.value.name,
                    content_ids: [product.value.vendor_code],
                    quantity: 1,
                    fbc: getCookie("_fbc"),
                    fbp: getCookie("_fbp"),
                    external_id: external_id,
                    useragent: window.navigator.userAgent,
                    url: `${window.location.origin}${window.location.pathname}`,
                });
            } catch (error) {
                console.error(error);
            }
        };

        const addToCart = () => {
            const variant = product.value.variants.find(variantMatches);

            let cart = localStorage.getItem("cart");
            if (cart) {
                cart = JSON.parse(cart);
            } else {
                cart = [];
            }

            let engraving = "";
            if (inputEngraving.value) {
                engraving = inputEngraving.value;
            } else if (selectedEngraving.value) {
                engraving = selectedEngraving.value;
            }

            let itemToAdd;
            if (variant) {
                itemToAdd = {
                    id: variant.id,
                    product_id: product.value.id,
                    variant_id: variant ? variant.id : null,
                    vendor_code: variant ? variant.vendor_code : product.value.vendor_code,
                    quantity: quantity.value,
                    product: product.value,
                    price: variantPrice.value,
                    percent: product.value.percent ?? 0,
                    is_set: 0,
                    sets: {},
                    variant: variant,
                    ...(engraving ? { engraving: engraving } : {}),
                };
            } else if (product.value.is_set == 1) {
                itemToAdd = {
                    id: product.value.id,
                    product_id: product.value.id,
                    variant_id: variant ? variant.id : null,
                    vendor_code: variant ? variant.vendor_code : product.value.vendor_code,
                    quantity: quantity.value,
                    product: product.value,
                    is_set: 1,
                    variant: {},
                    price: variantPrice.value,
                    percent: product.value.percent ?? 0,
                    sets: priceStack,
                    ...(engraving ? { engraving: engraving } : {}),
                };
            } else {
                itemToAdd = {
                    id: product.value.id,
                    product_id: product.value.id,
                    variant_id: variant ? variant.id : null,
                    vendor_code: variant ? variant.vendor_code : product.value.vendor_code,
                    quantity: quantity.value,
                    product: product.value,
                    price: variantPrice.value,
                    percent: product.value.percent ?? 0,
                    is_set: 0,
                    sets: {},
                    variant: {}, // An empty array for the variant.
                    ...(engraving ? { engraving: engraving } : {}),
                };
            }

            const existingProductIndex = cart.findIndex((item) => item.id === itemToAdd.id);
            if (existingProductIndex >= 0) {
                cart[existingProductIndex].quantity++;
            } else {
                cart.push(itemToAdd);
            }

            localStorage.setItem("cart", JSON.stringify(cart));
            document.dispatchEvent(new CustomEvent("cart-updated"));
            store.commit("openCart");

            store.dispatch("updateCart", cart);

            window.fbq("track", "AddToCart", {
                content_ids: product.value.vendor_code,
                content_type: "product",
                content_name: product.value.name,
                external_id: external_id,
                event_id: event_id,
                currency: "UAH",
                source: "le-perle.com.ua",
                value: parseFloat(variant?.price) || parseFloat(product?.value?.price),
            });

            dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
            dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                    value: totalPrice.value,
                    currency: "UAH",
                    items: [
                        {
                            item_name: product.value.name, // Name or ID is required.
                            item_id: product.value.vendor_code,
                            price: parseFloat(variant?.price) || parseFloat(product?.value?.price),
                            item_brand: "Le-perle",
                            item_category: product.value.category ? product.value.category.title.ua : "",
                            item_variant: variant?.vendor_code || product.value.vendor_code,
                            quantity: 1,
                        },
                    ],
                },
            });

            try {
                axios.post(apiUrl + "fbapi", {
                    action: "AddToCart",
                    event_id: event_id,
                    value: parseFloat(variant?.price) || parseFloat(product?.value?.price),
                    content_name: product.value.name,
                    content_ids: product.value.vendor_code,
                    quantity: 1,
                    fbc: getCookie("_fbc"),
                    fbp: getCookie("_fbp"),
                    external_id: external_id,
                    useragent: window.navigator.userAgent,
                    url: `${window.location.origin}${window.location.pathname}`,
                });
            } catch (error) {
                console.error(error);
            }
        };

        const setThumbsSwiper = (swiper) => {
            thumbsSwiper.value = swiper;
        };

        const variantMatches = (variant) => {
            if (selectedSize.value && variant.option_values.find((option) => option.option.name === "size")?.value !== selectedSize.value) {
                return false;
            }
            if (selectedLength.value && variant.option_values.find((option) => option.option.name === "length")?.value !== selectedLength.value) {
                return false;
            }
            if (selectedClasp.value && variant.option_values.find((option) => option.option.name === "clasp")?.value !== selectedClasp.value) {
                return false;
            }
            if (selectedColor.value && variant.option_values.find((option) => option.option.name === "color")?.value !== selectedColor.value) {
                return false;
            }
            return true;
        };

        const route = useRoute();
        // const router = useRouter();

        const slug = ref(route.params.slug); // первоначальное значение слага
        const vendorCode = ref(route.params.vendor_code); // Новый параметр vendor_code

        const getProduct = async () => {
            try {
                const response = await axios.get(apiUrl + "products/" + slug.value);
                product.value = response.data.data;

                window.scrollTo({ top: 0, behavior: "smooth" });

                let storedProducts = localStorage.getItem("viewedProducts");
                if (storedProducts) {
                    storedProducts = JSON.parse(storedProducts);
                } else {
                    storedProducts = [];
                }

                if (product.value.is_visible === 0) {
                    router.push("/"); // Редирект на нужный путь

                    return;
                }

                // Удаляем текущий продукт из массива, если он уже есть (чтобы потом добавить его в начало)
                storedProducts = storedProducts.filter((storedProduct) => storedProduct.id !== product.value.id);

                // Добавляем текущий просмотренный продукт в начало массива
                storedProducts.unshift(product.value);

                // Удаляем старые просмотренные продукты, если их больше 10
                if (storedProducts.length > 10) {
                    storedProducts.pop();
                }

                localStorage.setItem("viewedProducts", JSON.stringify(storedProducts));
                variantPrice.value = product.value.price;
                variantOldPrice.value = product.value.old_price;
                variantVendorCode.value = product.value.vendor_code;
                if (vendorCode.value) {
                    selectVariantByVendorCode(vendorCode.value);
                } else {
                    selectFirstAvailableOptions();
                }

                fbProduct();
                if (product.value.is_set === 1 && product.value.sets.length > 0) {
                    setsTotalPrice();
                    variantPrice.value = getTotalPrice();
                    console.log(variantPrice.value);
                    variantOldPrice.value = getOldTotalPrice();
                }
            } catch (error) {
                console.error(error);
                if (error.response && error.response.status === 404) {
                    // router.currentRoute.name = "NotFoundPage";
                    notFound.value = 1;
                }
            }
        };

        const updatePriceStack = (variant) => {
            priceStack[variant.product_id] = {
                price: variant.price_origin * 0.95,
                old_price: variant.old_price,
                variant_id: variant.variant_id,
                product_id: variant.product_id,
            };
            console.log(variant);
        };

        const getTotalPrice = () => {
            return Object.values(priceStack).reduce((total, price) => total + parseFloat(price.price), 0);
        };

        const getOldTotalPrice = () => {
            return Object.values(priceStack).reduce((total, price) => total + parseFloat(price.old_price), 0);
        };

        watch(selectedVariantsArray, (newValue) => {
            if (newValue) {
                updatePriceStack(newValue);
                variantPrice.value = getTotalPrice();
                variantOldPrice.value = getTotalPrice();
            }
        });

        function handleSelectedVariant(event) {
            updatePriceStack(event);

            setsTotalPrice(); // Добавляем цену продуктов без вариантов
            variantPrice.value = getTotalPrice();
            variantOldPrice.value = getOldTotalPrice();
        }

        function setsTotalPrice() {
            product.value.sets.forEach((set) => {
                if (set.variants.length === 0) {
                    priceStack[set.id] = {
                        price: set.price, // Если здесь должно быть число, возможно, стоит преобразовать заранее
                        origin_price: set.origin_price, // Если здесь должно быть число, возможно, стоит преобразовать заранее
                        old_price: set.old_price, // Если здесь должно быть число, возможно, стоит преобразовать заранее
                        variant_id: null,
                        product_id: set.id,
                    };
                }
            });
        }

        onMounted(async () => {
            await getProduct();
            const productData = product.value;
            if (!product.value) return;

            const metaDescription = document.querySelector('meta[name="description"]');
            if (metaDescription) {
                metaDescription.setAttribute("content", productData.description || "Описание по умолчанию");
            }
            // теперь, когда у нас есть все данные о продукте, мы можем их использовать
            if (product.value) {
                dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
                dataLayer.push({
                    event: "select_item",
                    ecommerce: {
                        items: [
                            {
                                item_name: product.value.name, // Name or ID is required.
                                item_id: product.value.vendor_code,
                                item_brand: "Le-Perle",
                                item_category: product.value.category ? product.value.category.title.ua : "",
                                item_variant: selectedVariant.value?.vendor_code ? selectedVariant.value?.vendor_code : "",
                                quantity: 1,
                                price: parseFloat(product.value.price) || "",
                            },
                        ],
                    },
                });
                dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
                dataLayer.push({
                    event: "view_item",
                    ecommerce: {
                        value: parseFloat(product.value.price) || "",
                        currency: "UAH",
                        items: [
                            {
                                item_name: product.value.name, // Name or ID is required.
                                item_id: product.value.vendor_code,
                                item_brand: "Le-Perle",
                                item_category: product.value.category ? product.value.category.title.ua : "",
                                item_variant: selectedVariant.value?.vendor_code ? selectedVariant.value?.vendor_code : "",
                                quantity: 1,
                                price: parseFloat(product.value.price) || "",
                            },
                        ],
                    },
                });
            }
        });

        // onMounted(() => {

        // });

        watch(
            () => route.params.slug,
            () => {
                slug.value = route.params.slug;
                getProduct();
            }
        );
        watchEffect(() => {
            if (product.value && product.value.name) {
                useHead({
                    title: `${product.value.name} – Le-perle`,
                    meta: [
                        {
                            name: "description",
                            content: product.value.description ?? "",
                        },
                        {
                            property: "og:title",
                            content: `${product.value.name} – Le-perle`,
                        },
                        {
                            property: "og:description",
                            content: product.value.description ?? "",
                        },
                        {
                            property: "og:image",
                            content: product.value.downloads[0]?.path ? `${uploadUrl.value}${product.value.downloads[0].path}` : "https://api.shushli-mushli.com.ua/assets/img/logo.svg",
                        },
                        {
                            property: "og:url",
                            content: "https://le-perle.com.ua/product/" + product.value.slug,
                        },
                        {
                            property: "og:type",
                            content: "website",
                        },
                    ],
                });
            }
        });

        return {
            modules: [Thumbs, Navigation, Pagination],
            thumbsSwiper,
            setThumbsSwiper,
            slug,
            product,
            viewedProducts: [],
            selectedSize,
            selectedLength,
            selectedClasp,
            selectedColor,
            addToCart,
            variantPrice,
            variantOldPrice,
            variantVendorCode,
            getAvailableSizes,
            getAvailableLengths,
            getAvailableClasps,
            getAvailableColors,
            quantity,
            sizeLabel,
            accordionData,
            toggleAccordion,
            isOpen,
            isMainButtonOutOfView,
            selectedEngraving,
            inputEngraving,
            notFound,
            selectedVariants,
            handleSelectedVariant,
            sizesCategory,
            isPopupSizesOpen,
            categorySizes,
        };
    },
    mounted() {
        this.getViewProducts();
        Fancybox.bind("[data-fancybox]", {});
    },
    methods: {
        getImageProduct(media) {
            return media.path ? this.$uploadUrl + media.path : "https://api.le-perle.com.ua/assets/img/logo.svg";
        },
        getViewProducts() {
            let storedProducts = localStorage.getItem("viewedProducts");
            if (storedProducts) {
                this.viewedProducts = JSON.parse(storedProducts);
            }

            return this.viewedProducts;
        },
        toggleShowEngraving() {
            this.isVisibleEngraving = this.isVisibleEngraving === 1 ? null : 1;
            if (!this.isVisibleEngraving) {
                this.inputEngraving = "";
            }
        },
        beforeEnter(el) {
            el.style.maxHeight = "0";
        },
        enter(el) {
            el.style.maxHeight = `${el.scrollHeight}px`;
            el.style.overflow = "unset";
        },
        beforeLeave(el) {
            el.style.maxHeight = `${el.scrollHeight}px`;
            el.style.overflow = "unset";
        },
        leave(el) {
            el.style.maxHeight = "0";
            el.style.overflow = "hidden";
        },
        cleanEngraving(engraving) {
            // Удаление символов возврата каретки
            return engraving.replace(/\r/g, "");
        },
    },
};
</script>

<style scoped>
/* .popup__sizes {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup__container {
    width: 500px;
    height: 500px;
    background: red;
} */

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
    opacity: 0;
    height: 0;
}

.accordion-item {
    border-bottom: 1px solid #000;
    padding: 20px 10px;
}
.accordion-item:first-child {
    border-top: 1px solid #000;
}
.accordion-item button {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.accordion-item ._icon-close {
    transform: rotate(45deg);
}
.accordion-item > div {
    margin-top: 10px;
}

.content-product__engraving .vs__dropdown-toggle {
    padding: 10px 21px;
    font-size: 16px;
    max-width: 375px;
    border-radius: 6px !important;
    border-color: #000000a6;
    /* max-width: 347px; */
    /* max-height: 50px; */

    @media (max-width: 768px) {
        padding: 5px 15px;
    }
}

.content-product__engraving .v-select {
    max-width: 375px !important;
}
.vs__selected {
    margin-top: 0 !important;
}

.vs__search {
}

.product__main-label {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 8px;
    background-color: #e74c3c;
    color: #fff;
    font-weight: 600;
    z-index: 1;
}

@media (max-width: 1024px) {
    .product__main-label {
        left: 20px;
    }
}
</style>
