<template>
    <main class="page">
        <section class="offerta">
            <div class="offerta__container">
                <div field="text" class="t-text t-text_md" style="">
                    <br /><br />
                    <p style="text-align: center">
                        <strong style="font-size: 22px"><span class="ql-cursor"></span>Договір публічної оферти</strong><br />
                        <strong style="font-size: 22px"><span class="ql-cursor"></span>Правила оплати, доставки та повернення</strong>
                    </p>
                    <br />
                    <p></p>

                    <p><span>Цей Договір публічної оферти (надалі – «Договір», «Оферта») є публічною пропозицією Фізичної особи-підприємця ФОП Токар Вікторія Леонідівна, РНОКПП 2639518765</span><span> («Продавець»), укласти договір купівлі-продажу товарів, що представлені на вебсайті https://le-perle.com.ua/</span></p>
                    <br />
                    <p>
                        <span>Укладенням цього Договору також вважатимуться конклюдентні дії: оплата рахунку, наданого Виконавцем та/або оплата товару за допомогою платіжної системи, що використовується на Сайті. </span>
                    </p>

                    <p>
                        <span>Уважно прочитайте Договір, оскільки він регулює купівлю-продаж Товарів, що розміщені на Сайті. Використовуючи наш Сайт, ви погоджуєтесь та зобов’язуєтесь дотримуватися його умов в повному обсязі.</span>
                    </p>
                    <br />
                    <p>
                        <span>Приймаючи цю Оферту, Ви погоджуєтесь з усіма викладеними нижче умовами та підтверджуєте, що Вам зрозумілі всі її положення та Ви згодні укласти на цих умовах цей Договір. </span>
                    </p>

                    <p>
                        <span>У разі, якщо Ви не погоджуєтесь з умовами цієї Оферти, будь ласка, не замовляйте та не оплачуйте Товари, що розміщені на цьому Сайті.</span>
                    </p>

                    <p>
                        <span>У разі, якщо Вам не зрозумілі певні положення, пропонуємо Вам зв'язатись з нами шляхом відправлення листа на електронну пошту: <a href="mailto:le.perle.ua@gmail.com">le.perle.ua@gmail.com</a>.</span>
                    </p>

                    <p>
                        <span>&#xa0;</span>
                    </p>

                    <p>
                        <strong><span>1. Визначення термінів та Загальні положення</span></strong>
                    </p>
                    <p>
                        <strong><span>1.1. Договір публічної оферти/ Договір/ Оферта</span></strong
                        ><span> – публічна пропозиція Продавця, адресована будь-якій фізичній або юридичній особі, укладення з ними </span><span>договору купівлі-продажу Товару дистанційним способом</span><span> через Інтернет-магазин на вебсайті: https://le-perle.com.ua/ на умовах, що містяться в цій Оферті.</span>
                    </p>
                    <p>
                        <strong><span>1.2. Вебсайт/ Сайт</span></strong
                        ><span> – сайт, розміщений у мережі Інтернет за адресою https://le-perle.com.ua/</span>
                    </p>
                    <p>
                        <strong><span>1.3. Покупець</span></strong
                        ><span> – будь-яка особа, що досягла 18 років та за власним волевиянням повністю прийняла (акцептувала) всі умови цього Договору без виключення.</span>
                    </p>
                    <p>
                        <strong><span>1.4. Товар</span></strong
                        ><span> – об'єкт угоди Сторін, який був обраний Покупцем на Сайті та придбаний Покупцем у Продавця дистанційним способом.</span>
                    </p>
                    <p>
                        <strong><span>1.5. Замовлення</span></strong
                        ><span> – рішення Покупця замовити Товар і його доставку, оформлене на Сайті https://le-perle.com.ua/.</span>
                    </p>
                    <p>
                        <strong><span>1.6. Подарунковий сертифікат</span></strong
                        ><span> – це електронний документ різного номіналу, що підтверджує платіж у вигляді передоплати за придбання та отримання Товарів на Сайті у майбутньому будь-якою особою.</span>
                    </p>
                    <p>
                        <strong><span>1.7. Утримувач сертифіката</span></strong
                        ><span> – будь-яка дієздатна особа, яка має у власності Подарунковий сертифікат та має намір здійснити ним оплату за Товар.</span>
                    </p>
                    <p>
                        <span>Усі інші терміни, що окремо не визначені в даному Договорі, сприймаються і тлумачаться в їх буквальному граматичному значенні виходячи з положень чинного законодавства України, звичаїв ділового обороту, а також мети та предмету даного Договору. </span>
                    </p>
                    <p>
                        <span>&#xa0;</span>
                    </p>
                    <p>
                        <strong><span>2. Предмет Договору</span></strong>
                    </p>
                    <p><span>2.1. Продавець зобов'язується передати у власність обраний Покупцем Товар, а Покупець зобов'язується </span><span>оплатити та прийняти Товар на умовах цього Договору.</span></p>
                    <p>
                        <span>2.2. Датою укладення Договору (акцептом оферти) та моментом повного й беззаперечного прийняття Покупцем умов Договору вважається дата оплати Товару через платіжну систему на Сайті або здійснення передплати за обраний Товар. У разі необхідності, за бажанням Покупця, Договір може бути оформлений у письмовій формі.</span>
                    </p>
                    <p>
                        <span>&#xa0;</span>
                    </p>
                    <p>
                        <strong><span>3. Оформлення Замовлення</span></strong>
                    </p>
                    <p>
                        <span>3.1. Покупець самостійно оформлює замовлення через Сайт, що здійснюється шляхом додавання обраного Товару до кошику з натисканням кнопки «Додати в кошик».</span>
                    </p>
                    <p>
                        <span>3.2. Продавець має право відмовитися від передання замовлення Покупцеві у випадку, якщо відомості, вказані Покупцем під час оформлення замовлення, є неповними або викликають підозру щодо їх дійсності.</span>
                    </p>
                    <p>
                        <span>3.3. При оформленні замовлення на Сайті, Покупець зобов'язується надати наступну обов'язкову інформацію, необхідну Продавцю для виконання замовлення:</span>
                    </p>
                    <p>
                        <span>3.3.1. прізвище, ім'я Покупця;</span>
                    </p>
                    <p>
                        <span>3.3.2. адреса доставки Товару або номер відділення служби доставки у відповідному населеному пункті;</span>
                    </p>
                    <p>
                        <span>3.3.3. контактний телефон отримувача Товару;</span>
                    </p>
                    <p>
                        <span>3.3.4. адресу електронної пошти Покупця.</span>
                    </p>
                    <p>
                        <span>3.4. Найменування, кількість, актуальна ціна обраного Покупцем Товару вказуються на Сайті.</span>
                    </p>
                    <p><span>3.5. Якщо будь-якій зі Сторін Договору необхідна додаткова інформація, він має право запросити її в іншої Сторони. У разі ненадання необхідної інформації Покупцем, або надання недостовірної інформації, Продавець не несе відповідальн</span><span>ості за наслідки, які можуть виникнути у зв'язку із ненаданням або наданням недостовірної інформації.</span></p>
                    <p>
                        <span>3.6. Прийняття Покупцем умов цієї Оферти здійснюється за умови оплати обраного Товару або здійснення передплати за нього. Після оформлення Замовлення через Сайт, дані про Покупця вносяться до бази даних Продавця.</span>
                    </p>
                    <p>
                        <span>3.7. Покупець несе відповідальність за достовірність наданої інформації при оформленні Замовлення.</span>
                    </p>
                    <p>
                        <span>3.8. На прохання чи за потребою Покупця, продаж Товару також може здійснюватись іншими способами, зокрема, при листування Продавця і Покупця в месенджері Telegram, Viber або соціальній мережі Instagram за посиланнями на контакти Продавця, що вказані на Сайті.</span>
                    </p>
                    <p>
                        <span>При цьому, порядок укладення цього Договору, порядок здійснення оплати та доставки здійснюються за умовами, що визначені в цьому Договорі.</span>
                    </p>
                    <p>
                        <span>3.9. Укладаючи Договір, тобто акцептуючи умови даної пропозиції (запропоновані умови придбання Товару), шляхом оформлення Замовлення, Покупець підтверджує наступне:</span>
                    </p>
                    <p>
                        <span>а) Покупець цілком і повністю ознайомлений, і погоджується з умовами цієї пропозиції (оферти);</span>
                    </p>
                    <p><span>б) Покупець дає дозвіл на збір, обробку та передачу персональних даних, дозвіл на обробку персональних даних діє протягом усього терміну дії Договору, а також протягом необмеженого терміну після закінчення його дії. Крім цього, укладенням договору Покупець підтверджує, що він повідомлений (без додаткового повідомлення) про права, встановлені Законом України «Про захист персональних даних», про цілі збору даних, а також про те, що його персональні дані передаються Продавцю з метою можливості виконання умов цього Договору, можливості проведення взаєморозрахунків, а також для отримання рахунків, актів та інших документів. Покупець також погоджується з тим, що Продавець має право надавати доступ та передавати його персональні дані третім особам без будь-яких додаткових повідомлень Покупця виключно з метою виконання замовлення Покупця. Обсяг прав Покупця, як суб'єкта персональних даних відповідно до Закону України «Про захист персональних даних» йому відомий і зрозумілий</span><span>;</span></p>
                    <p>
                        <span>в) Покупець ознайомився з правилами використання Товару та про гарантійні строки, що вказані на Сайті.</span>
                    </p>
                    <p>
                        <span>&#xa0;</span>
                    </p>
                    <p>
                        <strong><span>4. Ціна, Оплата та Доставка Товару</span></strong>
                    </p>
                    <p>
                        <span>4.1 Ціни на Товари визначаються Продавцем самостійно та вказані на Сайті. </span>
                    </p>
                    <p>
                        <span>4.2 Ціни на Товари можуть змінюватися Продавцем в односторонньому порядку залежно від кон'юнктури ринку. При цьому ціна окремої одиниці Товару, вартість якої оплачена Покупцем в повному обсязі або за яку внесено передплату, не може бути змінена Продавцем в односторонньому порядку.</span>
                    </p>
                    <p>
                        <span>4.3. Вартість Товару, яка вказана на Сайті не включає в себе вартість доставки Товару Покупцю, комісію платіжної системи та комісію за наложений платіж. Вартість доставки Товару Покупець сплачує відповідно до діючих тарифів служб доставки (перевізників) безпосередньо обраній ним службі доставки (перевізнику), із переліку, який пропонує Продавець, а також країни доставки Товару.</span>
                    </p>
                    <p>
                        <span>4.4. Оплата Товару здійснюється одним із способів на вибір:</span>
                    </p>
                    <p>
                        <span>4.4.1. Шляхом 100% передплати на Сайті платіжними картками Visa та MasterCard через верифіковану платіжну систему, що пропонується Сайтом;</span>
                    </p>
                    <p>
                        <span>4.4.2. Шляхом 100% передплати за Товар за наданими Покупцю реквізитами для оплати на банківський рахунок Продавця.</span>
                    </p>
                    <p><span>4.4.3. Шляхом оплати на банківський рахунок Продавця передплати у розмірі 150,00 грн. (сто п</span><span>`</span><span>ятдесят гривень 00 коп) та залишок коштів безпосередньо при отриманні Товару, якщо така функція передбачена компанією перевізником, яка здійснила доставку Товару. При цьому, доставку та комісію за наложений платіж оплачується Покупцем самостійно. </span></p>
                    <p>
                        <span>Передплата вважається авансом та при відмові Покупця отримати та сплатити замовлений Товар не повертається.</span>
                    </p>
                    <p>
                        <span>4.4.4. Іншим способом, що вказаний на Сайті в розділі «Доставка та Оплата» або безпосередньо під час оформлення замовлення на Сайті.</span>
                    </p>
                    <p>
                        <span>4.5. Зобов'язання Покупця по оплаті Товару вважаються виконаними з моменту надходження Продавцю коштів на його рахунок.</span>
                    </p>
                    <p><span>4.6. Відправка Товарів, які є в наявності у Продавця, відбувається протягом 1-3 днів з моменту підтвердження</span><span> Замовлення. Щодо Товарів, яких немає в наявності, сторони погоджують максимально розумні терміни. </span></p>
                    <p>
                        <span>4.7. Доставка в межах України зазвичай займає від 1 до 7 днів від дня відправки Товару в залежності від перевізника, але Продавець не несе відповідальності за затримки в доставці не з вини Продавця.</span>
                    </p>
                    <p><span>4.8. Міжнародна доставка </span><span>може здійснюватися іншим сервісом доставки. Вартість та строки такої доставки узгоджуються з Покупцем окремо за допомогою листування електронною поштою.</span></p>
                    <p>
                        <span>4.9. Невиконання Покупцем своїх зобов’язань щодо оплати замовленого ним Товару вважається односторонньою відмовою Покупця від даного Договору в повному обсязі, що, відповідно, має наслідком припинення в повному обсязі всіх зобов’язань Продавця, що виникли внаслідок прийняття Покупцем пропозиції Продавця щодо укладання даного Договору.</span>
                    </p>
                    <p>
                        <span>4.10. Усі розрахунки за цим Договором здійснюються виключно у національній валюті України. </span>
                    </p>
                    <p>
                        <span>4.11. При отриманні Товару Покупець повинен у присутності представника служби доставки (перевізника) перевірити відповідність Товару якісним і кількісним характеристикам (найменування товару, кількість, комплектність тощо).</span>
                    </p>
                    <p>
                        <span>Отримання Товару Покупцем свідчить про перевірку якісним і кількісним характеристикам (найменування товару, кількість, комплектність тощо) Товару та відсутність претензій з боку Покупця.</span>
                    </p>
                    <p>
                        <span>4.12. Цей Договір вважається виконаним в момент вручення (передання) Покупцю або Отримувачу Товару замовленого Покупцем комплектного Товару належної якості, що повністю відповідає погодженому Сторонами замовленню на цей Товар.</span>
                    </p>
                    <p>
                        <span>4.13. Відмова Покупця або Отримувача Товару від прийняття замовленого Товару, що є якісним, комплектним та повністю відповідає направленому Покупцем Замовленню на цей Товар та/або відмова Покупця або Отримувача Товару від підписання документів на цей Товар вважається односторонньою відмовою Покупця від даного Договору в повному обсязі, що, відповідно, має наслідком припинення в повному обсязі всіх зобов’язань Продавця, що виникли внаслідок прийняття Покупцем пропозиції Продавця щодо укладання даного Договору.</span>
                    </p>
                    <p>
                        <span>4.14. Право власності та ризик випадкової втрати або пошкодження Товару переходить до Покупця або його Представника з моменту отримання Товару Покупцем в місті поставки Товару при самостійній доставці Товару від Продавця, чи під час передачі Продавцем товару службі доставки (перевізнику) обраної Покупцем.</span>
                    </p>
                    <p>
                        <span>&#xa0;</span>
                    </p>
                    <p>
                        <strong><span>5. Права та обов'язки Сторін</span></strong>
                    </p>
                    <p>
                        <span>5.1. Продавець зобов'язаний:</span>
                    </p>
                    <p>
                        <span>5.1.1. Передати Покупцю Товар відповідно до умов цього Договору та Замовлення Покупця.</span>
                    </p>
                    <p>
                        <span>5.1.2. Не розголошувати будь-яку приватну інформацію про Покупця і не надавати доступ до цієї інформації третім особам, за винятком служб доставки та випадків, передбачених законодавством та під час виконання Замовлення Покупця.</span>
                    </p>
                    <p>
                        <span>5.1.3. Надати Покупцю електронний документ, квитанцію, товарний чи касовий чек, що підтверджує факт отримання коштів, із зазначенням дати здійснення розрахунку. В разі отримання Товару на поштовому відділенні (за умови післяплати із використанням послуги «наложений платіж» ) відповідний документ, що підтверджує факт оплати Товару, видає поштове відділення.</span>
                    </p>
                    <p>
                        <span>&#xa0;</span>
                    </p>
                    <p>
                        <span>5.2. Продавець має право:</span>
                    </p>
                    <p>
                        <span>5.2.1 Змінювати умови цього Договору, а також ціни на Товари, в односторонньому порядку, розміщуючи їх на Сайті. Всі зміни набувають чинності з моменту їх публікації та не стосуються раніше оплаченого Товару.</span>
                    </p>
                    <p>
                        <span>5.2.2. Проводити Розпродажі та пропонувати Акційні пропозиції, що передбачають тимчасову можливість придбання Товару на більш вигідних умовах ніж зазвичай.</span>
                    </p>
                    <p>
                        <span>5.2.3. Відмовитись від повернення коштів або заміни Товару, виконаного на замовлення або з індивідуальним гравіюванням.</span>
                    </p>
                    <p>
                        <span>&#xa0;</span>
                    </p>
                    <p>
                        <span>5.3 Покупець зобов'язується:</span>
                    </p>
                    <p>
                        <span>5.3.1 До моменту укладення Договору ознайомитися зі змістом Договору, умовами Договору і цінами, гарантійними умовами, запропонованими Продавцем на Сайті.</span>
                    </p>
                    <p>
                        <span>5.3.2 На виконання Продавцем своїх зобов'язань перед Покупцем останній повинен повідомити всі необхідні дані, що однозначно ідентифікують його як Покупця, і достатні для доставки Покупцеві замовленого Товару.</span>
                    </p>
                    <p>
                        <span>5.3.3. У порядку та на умовах, визначеним цим Договором, прийняти замовлений якісний та комплектний Товар.</span>
                    </p>
                    <p>
                        <span>&#xa0;</span>
                    </p>
                    <p>
                        <span>5.4. Покупець має право:</span>
                    </p>
                    <p>
                        <span>5.4.1. Вимагати від Продавця повного та належного виконання умов даного Договору;</span>
                    </p>
                    <p>
                        <span>5.4.2. Отримати замовлений Товар, що відповідає кількісним, якісним та номенклатурним характеристикам;</span>
                    </p>
                    <p>
                        <span>5.4.3. У випадку помилкового перерахунку більшої суми та/або повернення Товару, що 100% сплачений чи у разі не можливості виконати Продавцем оплачене замовлення, протягом 3-х календарних днів, з моменту настання одного з перерахованих випадків надати зворотні реквізити для повернення перерахованих коштів. При цьому, усі послуги з доставки до Покупця та повернення до Продавця сплачує Покупець.</span>
                    </p>
                    <p>
                        <span>5.4.4. Відмовитися від даного Договору в порядку та у випадках, передбачених даним Договором та чинним законодавством України;</span>
                    </p>
                    <p>
                        <span>5.4.5. У разі розірвання чи відмови від даного Договору вимагати від Продавця повернення грошових коштів, сплачених за Товар при 100% попередній оплаті. При цьому, Покупець не може вимагати повернення передплати, що передбачена п.4.4.3 цього Договору;</span>
                    </p>
                    <p>
                        <span>5.4.6. Здійснювати інші права, передбачені даним Договором та нормами чинного законодавства України.</span>
                    </p>
                    <p>
                        <span>&#xa0;</span>
                    </p>
                    <p>
                        <strong><span>6. Повернення, обмін Товару, усунення недоліків Товару та розірвання Договору</span></strong>
                    </p>
                    <p>
                        <span>6.1. Повернення Товару здійснюється відповідно до Закону України «Про захист прав споживачів». </span>
                    </p>
                    <p><span>6.2. </span><span>Покупець має право протягом 14 днів з моменту отримання Товару обміняти або повернути Товар належної якості на аналогічний у Продавця, якщо Товар не задовольнив його за змістом, тематикою або з інших причин не може бути ним використаний за призначенням.</span></p>
                    <p>
                        <span>Обмін або повернення Товару належної якості проводиться, якщо він не використовувався і якщо збережено його товарний вигляд, упаковка, споживчі властивості, пломби та ярлики, а також розрахунковий документ, виданий Покупцю разом з проданим Товаром.</span>
                    </p>
                    <p>
                        <span>При цьому, Покупець протягом вказаного строку повинен самостійно звернутися до Продавця, де вказати причини повернення, надати фото Товару з його комплектністю. </span>
                    </p>
                    <p><span>Продавець розглядає звернення протягом 3 (трьох) робочих днів і надає контакти для повернення такого Товару. Покупець самостійно оплачує послуги з доставки Товару до Продавця. Покупець при замовленні послуг з доставки Товару зобов</span><span>`</span><span>язаний оцінити посилку в реальну вартість Товару.</span></p>
                    <p><span>Продавець протягом 2 (двох) робочих днів перевіряє комплектність Товару, його цілісність, наявність </span><span>товарного вигляду, упаковки, споживчих властивостей, пломб та ярликів, що були видані Покупцю разом з проданим Товаром. При відсутності недоліків Товару, Продавець протягом 5 банківських днів повертає кошти за Товар або обмінює його на той Товар, який був обраний Покупцем для обміну.</span></p>
                    <p><span>Виключенням з цього пункту є Товари, що створені на замовлення або з індивідуальним гравіюванням, які поверненню не підлягають.</span><span>&#xa0; </span></p>
                    <p>
                        <span>6.3. У разі виявлення протягом встановленого гарантійного строку неістотних недоліків Товару, Покупець особисто звертається до Продавця з однією з вимог:</span>
                    </p>
                    <p class="rvps2" style="margin-top: 0pt; margin-bottom: 7.5pt; text-align: justify"><a id="n101"></a><span style="color: #333333">1) пропорційного зменшення ціни на Товар, якщо якісними характеристиками Товару це передбачено;</span></p>
                    <p class="rvps2" style="margin-top: 0pt; margin-bottom: 7.5pt; text-align: justify"><a id="n102"></a><span style="color: #333333">2) безоплатного усунення недоліків Товару в розумний строк;</span></p>
                    <p class="rvps2" style="margin-top: 0pt; margin-bottom: 7.5pt; text-align: justify"><a id="n103"></a><span style="color: #333333">3) відшкодування витрат на усунення недоліків Товару, що підтверджується відповідними фотографіями та чеками на оплату ремонту.</span></p>
                    <p><span>При цьому, Покупець зобов</span><span>`</span><span>язується у невідкладний строк з моменту виявлення такого неістотного недоліку звернутися до Продавця і описати недолік Товару з наданням підтверджуючих фото.</span></p>
                    <p>
                        <span>Продавець невідкладно, але не більше 3 (трьох) календарних днів розглядає відповідне звернення і пропонує шляхи вирішення такої ситуації.</span>
                    </p>
                    <p><span>При прийнятті Товару на </span><span style="color: #333333">безоплатного усунення недоліків Товару, Продавець зобов</span><span style="color: #333333">`</span><span style="color: #333333">язується надати Покупцю розумні строки для усунення недоліків</span><span>.</span></p>
                    <p>
                        <span>6.4. У разі виявлення протягом встановленого гарантійного строку істотних недоліків у Товарі, Покупець особисто звертається до Продавця з однією з вимог:</span>
                    </p>
                    <p class="rvps2" style="margin-top: 0pt; margin-bottom: 7.5pt; text-align: justify">
                        <span style="color: #333333">1) розірвання договору та повернення сплаченої за Товар грошової суми;</span>
                    </p>
                    <p class="rvps2" style="margin-top: 0pt; margin-bottom: 7.5pt; text-align: justify"><a id="n106"></a><span style="color: #333333">2) вимагати заміни Товару на такий же товар або на аналогічний, з числа наявних у Продавця Товар.</span></p>
                    <p><span>При цьому, Покупець зобов</span><span>`</span><span>язується у невідкладний строк з моменту виявлення такого істотного недоліку звернутися до Продавця і описати недолік Товару з наданням підтверджуючих фото.</span></p>
                    <p>
                        <span>Продавець невідкладно, але не більше 3 (трьох) календарних днів розглядає відповідне звернення і при наявності істотних недоліків Товару, задовольняє потреби Покупця протягом 14 календарних днів з моменту встановленням Продавцем істотних недоліків Товару.</span>
                    </p>
                    <p>
                        <span>6.5. Розгляд вимог, передбачених п. 6.3-6.4 цього Договору, провадиться Продавцем за умови надання Покупцем документів, що підтверджують купівлю Товару через Сайт. Продавець не відповідає за недоліки Товару, які виникли після його передання Покупцеві внаслідок порушення Покупцем правил користування або зберігання Товару, гарантійних умов, дій третіх осіб або непереборної сили.</span>
                    </p>
                    <p>
                        <span>6.6. Правила, що встановлені п. 6.2-6.4 цього Договору, не застосовуються для Товарів, що виготовленні на замовлення або на яких здійснено індивідуальне гравіювання.</span>
                    </p>
                    <p><span>При наявності істотних недоліків такого Товару під час гарантійного строку, Продавець зобов</span><span>`</span><span>язаний здійснити безкоштовне усунення таких істотних недоліків Товару.</span></p>
                    <p><span>При цьому, Покупець зобов</span><span>`</span><span>язується у невідкладний строк з моменту виявлення такого істотного недоліку звернутися до Продавця і описати недолік Товару з наданням підтверджуючих фото.</span></p>
                    <p>
                        <span>Продавець невідкладно, але не більше 3 (трьох) календарних днів розглядає відповідне звернення і пропонує розумні строки для усунення ним вказаного недоліку.</span>
                    </p>
                    <p>
                        <span>6.7. Розірвання договору допускається лише за згодою обох сторін Договору, якщо інше не встановлено Договором або чинним законодавством України. Договір може бути розірваний за рішенням суду на вимогу однієї зі сторін у разі істотного порушення договору іншою стороною. </span>
                    </p>
                    <p>
                        <span>6.8. В разі погодження продавцем здійснення грошового повернення, Покупець забов'язаний заповнити відповідну форму для повернення коштів на рахунок покупця, яка буде надіслана в Telegram, Viber, електронну пошту або номер телефону покупця, форма включає данні: ПІБ покупця, рахунок IBAN для повернення коштів, ІПН, та має бути заповнена покупцем. </span>
                    </p>
                    <p>
                        <span>&#xa0;</span>
                    </p>
                    <p>
                        <strong><span>7. Відповідальність</span></strong>
                    </p>
                    <p>
                        <span>7.1. Продавець не несе відповідальності за шкоду, заподіяну Покупцеві або третім особам внаслідок неналежного використання, зберігання Товару придбаного у Продавця.</span>
                    </p>
                    <p>
                        <span>7.2. Продавець не несе відповідальності за неналежне, несвоєчасне виконання Замовлень і своїх зобов'язань у випадку надання Покупцем недостовірної або помилкової інформації.</span>
                    </p>
                    <p>
                        <span>7.3. Продавець і Покупець несуть відповідальність за виконання своїх зобов'язань відповідно до чинного законодавства України й положень цього Договору.</span>
                    </p>
                    <p><span>7.4. Продавець не несе відповідальності за неналежне виконання своїх обов</span><span>`</span><span>язків службами доставки.</span></p>
                    <p>
                        <span>7.5. Продавець або Покупець звільняються від відповідальності за повне або часткове невиконання своїх зобов'язань, якщо невиконання є наслідком форс-мажорних обставин як: війна або військові дії, землетрус, повінь, пожежа та інші стихійні лиха, що виникли незалежно від волі Продавця і / або Покупця після укладення цього договору. Сторона, яка не може виконати свої зобов'язання, негайно повідомляє про це іншу Сторону.</span>
                    </p>
                    <p>
                        <span>&#xa0;</span>
                    </p>
                    <p>
                        <strong><span>8. Конфіденційність і захист персональних даних</span></strong>
                    </p>
                    <p>
                        <span>8.1. Надаючи свої персональні дані на Сайті при реєстрації або оформленні Замовлення, Покупець надає Продавцеві свою добровільну згоду на обробку, використання (у тому числі й передачу) своїх персональних даних, а також вчинення інших дій, передбачених Законом України «Про захист персональних даних», без обмеження терміну дії такої згоди.</span>
                    </p>
                    <p>
                        <span>8.2. Продавець зобов'язується не розголошувати отриману від Покупця інформацію. Не вважається порушенням надання Продавцем інформації службам доставки, контрагентам і третім особам, що діють на підставі договору з Продавцем, в тому числі й для виконання зобов'язань перед Покупцем, а також у випадках, коли розкриття такої інформації встановлено вимогами чинного законодавства України.</span>
                    </p>
                    <p>
                        <span>8.3. Покупець несе відповідальність за підтримання своїх персональних даних в актуальному стані. Продавець не несе відповідальності за неякісне виконання або невиконання своїх зобов'язань у зв'язку з неактуальністю інформації про Покупця або невідповідністю її дійсності.</span>
                    </p>
                    <p>
                        <span>&#xa0;</span>
                    </p>
                    <p>
                        <strong><span>9. Вирішення спорів</span></strong>
                    </p>
                    <p>
                        <span>9.1. Усі спори, що виникають між Покупцем і Продавцем, вирішуються шляхом переговорів. У випадку недосягнення врегулювання спірного питання шляхом переговорів, Покупець та/або Продавець мають право звернутися за вирішенням спору до судових органів відповідно до чинного законодавства України.</span>
                    </p>
                    <p>
                        <span>&#xa0;</span>
                    </p>
                    <p>
                        <strong><span>10. Інші умови</span></strong>
                    </p>
                    <p>
                        <span>10.1. Подарунковий сертифікат.</span>
                    </p>
                    <p>
                        <span>10.1.1. Продавець надає можливість Покупцю на Сайті придбати Подарунковий сертифікат.</span>
                    </p>
                    <p>
                        <span>10.1.2. Подарунковий сертифікат має фіксовані номінали, інформація про які вказується на Сайті в гривнях та дорівнює його вартості.</span>
                    </p>
                    <p>
                        <span>10.1.3. Під час придбання Подарункового сертифіката Покупцем сплачується грошова сума, яка дорівнює фіксованому номіналу обраного Подарункового сертифіката та є завдатком, який зараховується в рахунок оплати за Товар, який буде придбаний Утримувачем сертифікату у майбутньому.</span>
                    </p>
                    <p>
                        <span>10.1.3. Подарунковий сертифікат автоматично стає активним протягом 24 годин, з моменту отримання Подарункового сертифіката, але в будь якому разі не раніше отримання Продавцем повної оплати вартості Подарункового сертифіката.</span>
                    </p>
                    <p>
                        <span>10.1.4. Подарунковий сертифікат може бути використаний виключно під час оформлення одного замовлення через Сайт.</span>
                    </p>
                    <p>
                        <span>10.1.5. При здійсненні придбання Товару з використанням Подарункового сертифікату він деактивується (стає недійсним). </span>
                    </p>
                    <p>
                        <span>10.1.6. Якщо сума покупки перевищує номінальну вартість Подарункового сертифіката, Утримувач сертифіката зобов’язаний здійснити доплату різниці між ціною Товару та номіналом Подарункового сертифіката. </span>
                    </p>
                    <p><span>10.1.7. Якщо сума покупки є меншою ніж номінальна вартість Подарункового сертифіката, різниця між ціною Товару та номіналом Подарункового сертифіката,</span><span>&#xa0; </span><span>Утримувачу сертифіката не компенсується, не переноситься при наступних покупках, виплаті у будь-якій формі не підлягає. </span></p>
                    <p>
                        <span>10.1.8. Зобов’язання Продавця Подарункового сертифіката обмежується терміном дії Подарункового сертифіката, товарним асортиментом, наявним на Сайті, номіналом Подарункового сертифіката.</span>
                    </p>
                    <p>
                        <span>10.1.9. Термін дії Подарункового сертифіката обмежений та становить 12 календарних місяців з моменту його придбання.</span>
                    </p>
                    <p>
                        <span>10.1.10. У випадку, якщо протягом терміну дії Подарункового сертифіката, Утримувачем сертифіката не реалізоване право на придбання Товару, то зобов’язання Продавця Подарункового сертифіката вважаються припиненими, а грошова сума, сплачена за Подарунковий сертифікат поверненню не підлягає і залишається в розпорядженні Продавця.</span>
                    </p>
                    <p>
                        <span>10.1.11. Термін дії Подарункового сертифіката не може бути зміненим, продовженим або відновленим.</span>
                    </p>
                    <p>
                        <span>10.1.12. Продавець Подарункового сертифіката має право не приймати Подарунковий сертифікат у випадку наявності сумнівів в його оригінальності та/або наявності пошкоджень.</span>
                    </p>
                    <p>
                        <span>10.1.13. Ризик випадкової загибелі, пошкодження або втрати Подарункового сертифіката несе Покупець або Утримувач сертифіката з моменту придбання Подарункового сертифіката.</span>
                    </p>
                    <p>
                        <span>&#xa0;</span>
                    </p>
                    <p>
                        <strong><span>11. Застереження та права інтелектуальної власності </span></strong>
                    </p>
                    <p>
                        <span>11.1. Усі права на Сайт належать Продавцю.</span>
                    </p>
                    <p>
                        <span>11.2. Права інтелектуальної власності на Товари, що розроблені та виготовлені Продавцем, а також весь авторський матеріал, що використаний при їх створенні належать Продавцю.</span>
                    </p>
                    <p>
                        <span>11.3. Продавець також використовує об'єкти права інтелектуальної власності, що можуть належати іншим особам, на підставі відповідної ліцензії, договору чи іншої законної підстави.</span>
                    </p>
                    <p>
                        <span>11.4. Надаючи право на використання Покупцем об'єктів права використовувати для цілей виконання цього Договору, Продавець не передає будь-яких прав інтелектуальної власності на такі об'єкти та не надає дозволу на їх використання в цілях інших, ніж визначені Договором.</span>
                    </p>
                    <p>
                        <span>11.5. Усі вироби є авторськими та їх копіювання заборонено. За порушення вимог цієї статті передбачена відповідальність, встановлена чинним законодавством України.</span>
                    </p>
                    <p>
                        <span>&#xa0;</span>
                    </p>
                    <p>
                        <strong><span>12. Адреса та реквізити Продавця:</span></strong>
                    </p>
                    <p>
                        <span>ФОП Токар Вікторія Леонідівна </span>
                    </p>
                    <p><span>код ЄДРПОУ: </span><span>2639518765</span></p>
                    <p>
                        <span>Україна, 03118, місто Київ, </span>
                    </p>
                    <p>
                        <span>тел. </span><span> <a href="tel:+380739686148">+38(073)-968-61-48</a></span>
                    </p>
                    <p>
                        <span>e-mail: <a href="mailto:le.perle.ua@gmail.com">le.perle.ua@gmail.com</a></span>
                    </p>
                    <p>
                        <span>&#xa0;</span>
                    </p>
                </div>
            </div>
        </section>
    </main>
</template>

<script></script>

<style></style>
