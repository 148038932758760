<template>
    <div class="clasp" v-if="filteredColors.length > 0">
        <div class="clasp__title">{{ title }}</div>
        <div class="clasp__items">
            <div class="clasp__item" v-for="(item, index) in filteredColors" :key="index" :class="{ active: activeIndex === index }" :style="{ backgroundColor: item.color_hex }" @click="selectColor(index)">
                <!-- {{ item.color }} -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "colorsProduct",
    props: {
        title: String,
        colors: Array,
    },
    data() {
        return {
            activeIndex: 0,
        };
    },
    computed: {
        filteredColors() {
            return this.colors.filter((item) => item.color_hex !== null && item.color_hex !== "");
        },
    },
    methods: {
        selectColor(index) {
            this.activeIndex = index;
            this.$emit("selected", this.filteredColors[index].color);
        },
    },
};
</script>

<style>
/* .clasp__item {
    max-width: 71px;
    max-height: 71px;
} */
</style>
