export const global = {
    state: () => ({
        isCartOpen: false,
        apiUrl: process.env.VUE_APP_PROD_API_URL,
    }),
    getters: {
        cartItemCount: () => {
            const cartItems = localStorage.getItem("cart");
            if (!cartItems) return 0;

            const cart = JSON.parse(cartItems);
            return cart.reduce((total, item) => total + item.quantity, 0);
        },
    },
    mutations: {
        toggleCart(state) {
            state.isCartOpen = !state.isCartOpen;
        },
        openCart(state) {
            state.isCartOpen = true;
        },
        closeCart(state) {
            state.isCartOpen = false;
        },
    },
    actions: {
        getImageProduct(media) {
            return media.path ? this.$uploadUrl + media.path : "https://api.le-perle.com.ua/assets/img/logo.svg";
        },
    },
};
