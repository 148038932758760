<template>
    <div class="top-page">
        <div class="top-page__container">
            <div class="top-page__breadcrumbs breadcrumbs">
                <ul class="breadcrumbs__list">
                    <li class="breadcrumbs__item"><a href="" class="breadcrumbs__link">Le’Perle</a></li>
                    <li class="breadcrumbs__item"><span class="breadcrumbs__current">Мій список бажань</span></li>
                </ul>
            </div>
            <div class="top-page__body">
                <h1 class="top-page__title" data-title="my wishlist">Мій список бажань</h1>
                <div class="top-page__control">
                    <button class="top-page__button top-page__button_hide _icon-filter" @click="toggleOpenFilter">Фільтри</button>
                </div>
            </div>
        </div>
    </div>

    <section class="favorite-page">
        <div class="favorite-page__container">
            <div class="favorite-page__items product-items">
                <ProductCartFavorite v-for="(product, index) in wishlistProducts" :key="index" :product="product" @removedFromWishlist="getWishlistProducts" />
            </div>
        </div>
    </section>

    <ProductSlider :title="'Інші клієнти також купили'" :products="viewedProducts" id="1" />
    <ProductSlider :title="'Останні переглянуті товари'" :products="viewedProducts" id="2" />
</template>

<script>
import ProductCartFavorite from "./ProductCartFavorite.vue";
import ProductSlider from "./ProductSlider.vue";
export default {
    components: {
        ProductCartFavorite,
        ProductSlider,
    },
    data() {
        return {
            isFilterOpen: false,
            viewedProducts: [],
            wishlistProducts: [],
        };
    },
    methods: {
        toggleOpenFilter() {
            this.isFilterOpen = !this.isFilterOpen;
        },
        getViewProducts() {
            let storedProducts = localStorage.getItem("viewedProducts");
            if (storedProducts) {
                this.viewedProducts = JSON.parse(storedProducts);
            }

            return this.viewedProducts;
        },
        getWishlistProducts() {
            let storedProducts = localStorage.getItem("wishlist");
            if (storedProducts) {
                this.wishlistProducts = JSON.parse(storedProducts);
            } else {
                this.wishlistProducts = []; // обнуляем список избранных товаров, если его нет в localStorage
            }

            return this.wishlistProducts;
        },
    },
    mounted() {
        this.getViewProducts();
        this.getWishlistProducts();
    },
};
</script>

<style></style>
