<template>
    <div class="thanks">
        <div class="thanks__container">
            <div class="thanks__wrapper">
                <div class="thanks__image">
                    <img src="../assets/img/thanks.jpg" alt="thanks.jpg" />
                </div>
                <div class="thanks__content">
                    <h1>Дякуємо за замовлення!</h1>
                    <p>Ваше замовлення №{{ orderId }} оформлене! Лист підтвердження відправлено на ваш e-mail</p>
                </div>
                <div class="thanks__buttons" bis_skin_checked="1">
                    <a href="/" class="thanks__button button button_line"> Продовжити покупки </a>
                    <a href="/" class="thanks__button button"> Перейти на головну </a>
                </div>
            </div>
        </div>
    </div>
    <ProductSlider :title="'Інші клієнти також купили'" :products="viewedProducts" id="1" />
    <ProductSlider :title="'Останні переглянуті товари'" :products="viewedProducts" id="2" />
</template>
<script>
// /* global dataLayer */
/* global KTracking */

// import { onMounted, ref, computed, getCurrentInstance } from "vue";
import { onMounted, ref } from "vue";
import ProductSlider from "./ProductSlider.vue";
import { useRoute } from "vue-router";
// import { useStore } from "vuex";
// import axios from "axios";

export default {
    components: {
        ProductSlider,
    },
    data() {
        return {
            viewedProducts: [],
        };
    },
    setup() {
        const route = useRoute();
        const orderId = ref(null);
        // const store = useStore();
        // const totalPrice = computed(() => store.getters.totalPriceCart);
        // const component = getCurrentInstance(); // Получение доступа к экземпляру компонента
        // const apiUrl = process.env.VUE_APP_PROD_API_URL;
        // const getCookie = component.appContext.config.globalProperties.$getCookie;
        // const getEventId = component.appContext.config.globalProperties.$getEventId;
        // const event_id = getEventId();
        // const external_id = localStorage.getItem("external_id");
        onMounted(async () => {
            //     // Получаем ID заказа
            orderId.value = route.params.orderId;

            if (!window.KTracking) {
                window.KTracking = {
                    collectNonUniqueClicks: false,
                    multiDomain: false,
                    R_PATH: "https://kt.trafficjack.team/RC21XY",
                    P_PATH: "https://kt.trafficjack.team/50f7fac/postback",
                    listeners: [],
                    reportConversion: function () {
                        this.queued = arguments;
                    },
                    getSubId: function (fn) {
                        this.listeners.push(fn);
                    },
                    ready: function (fn) {
                        this.listeners.push(fn);
                    },
                };
            }

            (function () {
                var a = document.createElement("script");
                a.type = "application/javascript";
                a.async = true;
                a.src = "https://kt.trafficjack.team/js/k.min.js";
                var s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(a, s);
            })();

            const revenue = 110;
            const status = "sale";
            const tid = Math.floor(Math.random() * 1000000000);
            KTracking.reportConversion(revenue, status, { tid });

            //     let products = null;
            //     // Получаем товары из localStorage
            //     let storedProducts = JSON.parse(localStorage.getItem("cart") || "[]");
            //     // Преобразуем их в нужный формат
            //     products = storedProducts.map((product) => {
            //         // let categoryTitle;
            //         // if (Array.isArray(product.category)) {
            //         //     categoryTitle = product.category[0]?.title;
            //         // } else if (typeof product.category === "object") {
            //         //     categoryTitle = product.category?.title?.ua;
            //         // }
            //         return {
            //             item_name: product.product.name,
            //             item_id: product.product.id,
            //             price: product.variant?.price ? product.variant.price : product.product.price,
            //             item_brand: "Le-Perle",
            //             item_category: product.product.category?.title?.ua || product.product.category[0]?.title || "",
            //             item_variant: product.variant.id ? product.variant.id : product.product.id,
            //             quantity: product.quantity,
            //         };
            //     });
            //     // Вычисляем общую стоимость и количество товаров
            //     const totalValue = products.reduce((acc, product) => acc + product.price * product.quantity, 0);
            //     const totalItems = products.reduce((acc, product) => acc + product.quantity, 0);
            //     // Собираем ID продуктов
            //     const productIds = products.map((product) => product.item_id);
            //     // Отправляем событие в Facebook Pixel
            //     window.fbq("track", "Purchase", {
            //         value: totalValue, // Общая стоимость заказа
            //         currency: "UAH", // Валюта
            //         content_ids: productIds, // ID продуктов
            //         content_type: "product", // Тип контента
            //         event_id: event_id,
            //         order_id: orderId.value,
            //         external_id: external_id,
            //         num_items: totalItems, // Общее количество товаров
            //     });
            //     dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
            //     dataLayer.push({
            //         event: "purchase",
            //         ecommerce: {
            //             transaction_id: orderId.value,
            //             value: totalPrice.value,
            //             currency: "UAH",
            //             items: products,
            //         },
            //     });
            //     try {
            //         axios.post(apiUrl + "fbapi", {
            //             action: "Purchase",
            //             event_id: event_id,
            //             num_items: totalItems, // Общее количество товаров
            //             content_ids: productIds, // ID продуктов
            //             value: totalValue,
            //             phone: localStorage.getItem("phone"),
            //             email: localStorage.getItem("email"),
            //             fbc: getCookie("_fbc"),
            //             fbp: getCookie("_fbp"),
            //             order_id: orderId.value,
            //             external_id: external_id,
            //             useragent: window.navigator.userAgent,
            //             url: `${window.location.origin}${window.location.pathname}`,
            //         });
            //     } catch (error) {
            //         console.error(error);
            //     }
        });
        // // localStorage.setItem("cart", JSON.stringify([]));
        // // document.dispatchEvent(new CustomEvent("cart-updated"));
        return {
            orderId,
        };
    },
    mounted() {
        this.getViewProducts();
    },
    methods: {
        getViewProducts() {
            let storedProducts = localStorage.getItem("viewedProducts");
            if (storedProducts) {
                this.viewedProducts = JSON.parse(storedProducts);
            }

            return this.viewedProducts;
        },
    },
    watch: {},
};
</script>

<style></style>
