<template>
    <div class="sorting">
        <div :class="{ sorting__top: true, active: isOpenSorting }" @click="toggleSortingActive">
            <div class="sorting__title">Сортувати:</div>
            <div class="sorting__current" ref="sortingCurrent">
                <span>{{ selectedSorting.text }}</span>
            </div>
            <ul class="sorting__list" v-show="isOpenSorting">
                <li class="sorting__item" v-for="(sorting, index) in sortingOptions" :key="index" :class="{ active: sorting.value === selectedSorting.value }" @click.stop="selectSortingAndClose(sorting)">
                    {{ sorting.text }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "SortingBtn",
    components: {},
    data() {
        return {
            isOpenSorting: false,
            selectedSorting: { value: "default", text: "за замовчуванням" },
            sortingOptions: [
                { value: "default", text: "за замовчуванням" },
                { value: "asc", text: "від нижчих по вартості" },
                { value: "desc", text: "від вищих по вартост" },
                { value: "latest", text: "по новизні" },
            ],
        };
    },
    mounted() {
        this.addClickOutsideListener();
    },
    beforeUnmount() {
        this.removeClickOutsideListener();
    },
    methods: {
        toggleSortingActive() {
            this.isOpenSorting = !this.isOpenSorting;
        },
        addClickOutsideListener() {
            document.addEventListener("click", this.handleClickOutside);
        },
        removeClickOutsideListener() {
            document.removeEventListener("click", this.handleClickOutside);
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.isOpenSorting = false;
            }
        },
        selectSortingAndClose(sorting) {
            this.selectedSorting = sorting;
            this.isOpenSorting = false;
            this.$emit("sortingChanged", sorting.value); // Emit event to parent component
        },
    },
};
</script>

<style></style>
