<template>
    <main class="page">
        <section class="offerta">
            <div class="offerta__container">
                <div field="text" class="t-text t-text_md" style="">
                    <br /><br />
                    <p style="text-align: center">
                        <strong style="font-size: 22px"><span class="ql-cursor"></span>Правила повернення</strong>
                    </p>
                    <br />
                    <ol></ol>
                    <br />
                    <strong> Порядок повернення товару належної якості: </strong>
                    <br />
                    6.1. Покупець має право відмовитися від поставленого товару в момент отримання від кур’єра, або в пункті видачі.
                    <br />
                    6.2. Повернення товару в Інтернет-магазин можливе в разі, якщо прикраси не мають слідів носки. Крім того, повинна бути збережені оригінальна упаковка.
                    <br />
                    6.3. Повернення товару належної якості в Інтернет-магазин проводиться за рахунок Покупця.
                    <br />
                    6.4. При поверненні Покупцем товару належної якості, Інтернет-магазин повертає йому сплачену за товар грошову суму: за фактом повернення товару, за вирахуванням компенсації витрат магазинупов’язаних з доставкою товару Покупцеві, якщо такі витрати є.
                    <br />
                    <strong> <br />Порядок повернення товару не належної якості: </strong>
                    <br />
                    6.5. Протягом 14 днів з моменту покупки Ви маєте можливість здійснити обмін обраного товару на інший.
                    <br />
                    6.6. Обмін або повернення можливі лише в тому випадку, якщо збережено оригінальну упаковку та користувач притримувався всіх наданих йому рекомендацій по експлуатації товару.
                    <br />
                    6.7. Повернення товару не належної якості в Інтернет-магазин проводиться за рахунок Магазину.
                    <br />
                    <br />
                    <p class="text">
                        Розуміємо що непорозуміння та людьський фактор мають місце.<br /><br />

                        На жаль, навіть найвідоміші бренди не застраховані від збою на виробництві.<br /><br />

                        Переплутали позицію у замовленні? Під час перевірки посилки побачили пошкоджений товар? Виявили дефект товару?<br /><br />

                        Ми допоможемо вирішити Ваше питання.<br /><br />

                        Якщо при отриманні замовлення курʼєром або у відділенні поштових служб-партнерів доставлений товар не відповідає замовленню, Ви можете відмовитися від замовлення та запросити обмін за рахунок магазину.<br /><br />

                        Якщо проблема з товаром виявилась пізніше, будь ласка, напишіть нам на пошту, вказавши номер замовлення, коротко опишіть ситуацію, додайте фото та відео товару, який Ви б хотіли повернути або обміняти.<br /><br />

                        Ми намагаємося вирішити будь-яку ситуацію найкращим чином та в найкоротші терміни.
                    </p>

                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        </section>
    </main>
</template>

<script></script>

<style scoped>
.t-text {
    line-height: 20px;
}
.text {
    /* font-size: 16px; */
    /* line-height: 24px; */
    /* color: #000; */
}
</style>
