<template>
    <div class="popup popup-cart popup__sizes" :class="{ 'popup-show': show }">
        <div class="popup__wrapper">
            <div class="popup__content">
                <div class="cart">
                    <div class="cart__top-wrapper">
                        <div class="cart__top top-cart">
                            <div class="top-cart__title"></div>
                            <button type="button" class="popup__close _icon-close" @click="closePopup"></button>
                        </div>
                        <div class="cart__top top-cart">
                            <img :src="require('@/assets/img/sizes/kolye_sizes.png')" alt="kolye_sizes.PNG" v-if="category == '34'" />
                            <img :src="require('@/assets/img/sizes/braslet_sizes.webp')" alt="braslet_sizes.webp" v-if="category == '19'" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PopupSizes",
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        category: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const closePopup = () => {
            emit("update:show", false); // Генерация события для обновления свойства
        };

        return {
            closePopup,
        };
    },
};
</script>

<style scope>
.popup__sizes img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.popup__sizes .cart {
    padding: 0 !important;
}
.popup__sizes .popup__wrapper {
    height: auto;
}
</style>
