<template>
    <div class="sizes" v-if="sizes.length > 0 && sizes[0] !== null && sizes.some((size) => size !== '')">
        <div class="sizes__title">{{ title }}</div>
        <div class="sizes__items">
            <div class="sizes__item" v-for="(item, index) in sizes" :key="index" :class="{ active: activeIndex === index }" @click="selectSize(index)">
                {{ item }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SizesProduct",
    props: {
        title: String,
        sizes: Array,
        selectedSize: String, // Новый пропс для выбранного размера
    },
    data() {
        return {
            activeIndex: 0,
        };
    },
    watch: {
        selectedSize: {
            immediate: true,
            handler(newSize) {
                if (newSize) {
                    this.activeIndex = this.sizes.indexOf(newSize);
                }
            },
        },
    },
    methods: {
        selectSize(index) {
            this.activeIndex = index;
            this.$emit("selected", this.sizes[index]);
        },
    },
};
</script>
