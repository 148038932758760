// eventidGenerate.js
export default {
    install(app) {
        app.config.globalProperties.$getEventId = () => {
            const timestamp = Date.now(); // Получение временной метки
            const randomPart = Math.random().toString(36).substring(2, 15); // Генерация случайной строки
            return `event_${timestamp}_${randomPart}`;
        };
    },
};
