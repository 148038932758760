<template>
    <section class="order" v-if="cart && cart.length !== 0">
        <div class="order__container">
            <div class="order__body">
                <div class="order__content">
                    <div class="order__top top-block">
                        <h1 class="top-block__title title">Оформлення замовлення</h1>
                    </div>
                    <div class="order__maintabs maintabs tabs">
                        <div class="tabs__content">
                            <div class="tabs__body" :class="{ _active: activeTab === 0 }">
                                <div class="order__title" ref="fieldsWrapperContacts" @click="toggleIsVisibleContacts(true)">
                                    1. Заповніть свої данні
                                    <div>
                                        <img class="order__title-check" src="../assets/img/check-green.svg" alt="" v-if="!isVisibleContacts" />
                                        <img class="order__title-arrow" src="../assets/img/up-arrow.svg" alt="" v-if="!isVisibleContacts" />
                                    </div>
                                </div>
                                <transition name="accordion" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
                                    <div class="order__col" v-show="isVisibleContacts">
                                        <div class="order__row">
                                            <input :class="{ 'input-error': !isValid('firstName') && attemptedSubmit }" @blur="fieldsWrapperContacts" v-model="fieldfirstName" class="input" name="firstName" type="text" placeholder="Ваше ім’я *" ref="firstName" />
                                            <input :class="{ 'input-error': !isValid('lastName') && attemptedSubmit }" @blur="fieldsWrapperContacts" v-model="fieldlastName" class="input" name="lastName" type="text" placeholder="Ваше прізвище *" ref="lastName" />
                                        </div>
                                        <div class="order__row">
                                            <div class="order__field">
                                                <vue-tel-input v-model="fieldPhone" @country-changed="phoneChangeCountry" :validCharactersOnly="true" :inputOptions="{ placeholder: 'Телефон *', autocomplete: 'tel', type: 'tel' }" @validate="phoneValidate" :class="{ 'input-error': !isValid('phone') && !this.phoneValid && attemptedSubmit }" @blur="fieldsWrapperContacts" class="input" name="phone" ref="phone" placeholder="Телефон *" />
                                                <small class="order__field-error" v-show="!phoneValid">Введіть коректний номер телефону</small>
                                            </div>
                                            <!-- <input :class="{ 'input-error': !isValid('phone') && attemptedSubmit }" v-mask="'+38 (###) ###-##-##'" @blur="fieldsWrapperContacts" v-model="fieldPhone" class="input" name="phone" type="text" placeholder="Телефон *" ref="phone" /> -->
                                            <input :class="{ 'input-error': !isValid('email') && attemptedSubmit }" @blur="fieldsWrapperContacts" v-model="fieldEmail" class="input" name="email" type="email" placeholder="E-mail" ref="email" />
                                            <!-- <input @blur="fieldsWrapperContacts" v-model="fieldEmail" class="input" name="email" type="email" placeholder="E-mail" ref="email" /> -->
                                        </div>
                                    </div>
                                </transition>

                                <div class="order__title" ref="fieldsWrapperDelivery" @click="toggleIsVisibleDelivery(true)">
                                    2. Оберіть спосіб доставки
                                    <div>
                                        <img class="order__title-check" src="../assets/img/check-green.svg" alt="" v-if="!isVisibleDelivery" />
                                        <img class="order__title-arrow" src="../assets/img/up-arrow.svg" alt="" v-if="!isVisibleDelivery" />
                                    </div>
                                </div>
                                <transition name="accordion" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
                                    <div class="order__inputs order__col inputs-order" v-show="isVisibleDelivery">
                                        <OrderDelivery @delivery-data="handleDeliveryData" :firstName="fieldfirstName" :lastName="fieldlastName" />
                                    </div>
                                </transition>

                                <div class="order__title" ref="fieldsWrapperPayments">3. Варіанти оплати</div>
                                <transition name="accordion" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
                                    <ChekboxesOrder :key="paymentMethodsKey" :items="paymentMethods" @selected-pay-method="handlePayMethod" @create-pay-method="createPayMethod" />
                                </transition>
                                <div class="order__title order__title-smaller" @click="toggleIsVisiblePromocode"><u>Маєш промокод?</u></div>
                                <transition name="accordion" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
                                    <div class="order__col" v-show="isVisiblePromocode">
                                        <div class="order__row">
                                            <input ref="promoCode" @input="checkPromoCode" class="input" name="promocode" max="7" type="text" placeholder="Ваш промокод" />
                                        </div>
                                        <div class="order__row">
                                            <span id="promocodeStatus" style="margin-bottom: 10px"></span>
                                        </div>
                                    </div>
                                </transition>
                                <div class="order__title order__title-smaller" @click="toggleIsVisibleComment"><u>Додати коментар</u></div>
                                <transition name="accordion" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
                                    <div class="order__col" v-show="isVisibleComment">
                                        <div class="order__row">
                                            <textarea name="comment" ref="comment" class="textarea input" placeholder="Коментар до замовлення"></textarea>
                                        </div>
                                    </div>
                                </transition>
                                <div class="order__col">
                                    <button class="order__button button" @click="submitOrder" :disabled="isButtonOrderDisabled" :style="{ opacity: isButtonOrderDisabled ? 0.5 : 1 }">Перейти до оплати</button>
                                </div>
                                <div class="order__col">
                                    <Chekbox title="Я не потребую дзвінка менеджера" @change="handleCheckboxChange('managerCall', $event)" />
                                    <Chekbox isChekedDefault="true"
                                        ><span>Я погоджуюся з <router-link :to="{ name: 'Offerta' }" target="_blank">договором оферти і політикою конфіденційності</router-link></span></Chekbox
                                    >
                                </div>
                                <div id="formContainer"></div>
                            </div>
                            <div class="tabs__body" :class="{ _active: activeTab === 1 }">
                                <div class="order__register register-order">
                                    <div class="register-order__text">Якщо ви вже зареєстрований клієнт, то введіть свій e-mail, а якщо ви ще не реєструвались то перейдіть до кнопки “Новий Покупець”</div>
                                    <div class="register-order__line">
                                        <input class="input" type="text" placeholder="E-mail" />
                                        <div class="register-order__buttons">
                                            <button class="register-order__link">Забули пароль?</button>
                                            <button class="register-order__button button">Увійти</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <Cart /> -->
                <CartOrder :discountData="discountData" :discountType="discountType" />
            </div>
        </div>
    </section>
    <section v-if="typeof cart === 'undefined' || (cart && cart.length === 0)">
        <div class="thanks">
            <div class="thanks__container">
                <div class="thanks__wrapper">
                    <div class="thanks__content">
                        <h1>Кошик пустий!</h1>
                    </div>
                    <div class="thanks__buttons" bis_skin_checked="1">
                        <!-- <a href="/" class="thanks__button button button_line"> Продовжити покупки </a> -->
                    </div>
                    <a href="/" class="thanks__button button" style="width: 100%"> Перейти на головну </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
/* global dataLayer */
// import Cart from "./Cart"
import axios from "axios";
import CartOrder from "./CartOrder";
import Chekbox from "./Chekbox";
import ChekboxesOrder from "./ChekboxesOrder";
import OrderDelivery from "./OrderDelivery";
import { useToast } from "vue-toast-notification";
import { mask } from "vue-the-mask";
import { ref, onMounted, computed, watch } from "vue";

export default {
    setup() {
        const apiUrl = process.env.VUE_APP_PROD_API_URL;
        const productsCashlessPaymentsOnly = ref([]);
        const cashlessPaymentsOnly = ref(false);
        const cashlessPaymentsOnlyDelivery = ref(false);
        const toast = useToast();
        const paymentMethodsKey = ref(0);

        // Загрузка данных о продуктах только для безналичной оплаты
        const getCashlessPaymentsOnly = async () => {
            try {
                const response = await axios.get(`${apiUrl}products/tags/cashless_payments_only`);
                productsCashlessPaymentsOnly.value = response.data.data.map((product) => product.id);
                checkCashlessPaymentsOnly();
            } catch (error) {
                console.error("Ошибка при получении данных о продукте:", error);
            }
        };

        const updateCashlessPaymentsOnly = (value) => {
            cashlessPaymentsOnlyDelivery.value = value;
        };

        // Проверка корзины на наличие продуктов только для безналичной оплаты
        const checkCashlessPaymentsOnly = () => {
            const cart = JSON.parse(localStorage.getItem("cart") || "[]");
            cashlessPaymentsOnly.value = cart.some((item) => productsCashlessPaymentsOnly.value.includes(item.product_id));
        };

        onMounted(() => {
            getCashlessPaymentsOnly();
        });

        // Вычисляемое свойство для определения доступных способов оплаты
        const paymentMethods = computed(() => {
            const allMethods = [
                { image: require("../assets/img/order/monopay.png"), text: "Monopay", method: "mono", commission: "Комісія 0%: Apple Pay, Google Pay, Платіжні картки" },
                // { image: require("../assets/img/order/wfp.png"), text: "WayForPay", method: "wayforpay", commission: "Комісія 2%: Apple Pay, Google Pay, Платіжні картки" },
                { image: require("../assets/img/order/pay.png"), text: "При отриманні", method: "cash", commission: "Комісія: 20 грн. + мін. передплата 150 грн" },
            ];

            if (cashlessPaymentsOnly.value || cashlessPaymentsOnlyDelivery.value) {
                // Исключаем метод оплаты наличными, когда требуется только безналичная оплата
                return allMethods.filter((method) => method.method !== "cash");
            }
            return allMethods;
        });

        watch(paymentMethods, () => {
            paymentMethodsKey.value++; // Изменит ключ при каждом обновлении paymentMethods, заставляя перерендерить ChekboxesOrder
        });

        return {
            paymentMethods,
            cashlessPaymentsOnly,
            paymentMethodsKey,
            toast,
            updateCashlessPaymentsOnly,
        };
    },
    components: {
        // Cart,
        CartOrder,
        Chekbox,
        ChekboxesOrder,
        OrderDelivery,
    },
    directives: { mask },

    data() {
        return {
            activeTab: 0,
            apiUrl: process.env.VUE_APP_PROD_API_URL,
            deliveryData: null, // добавьте это свойство для хранения данных о доставке
            selectedPayMethod: null,
            attemptedSubmit: false,
            discountType: 0,
            discountData: 0,
            fieldfirstName: "",
            fieldlastName: "",
            fieldPhone: "",
            fieldEmail: "",
            isVisibleComment: false,
            isVisiblePromocode: false,
            isVisiblePayments: true,
            isVisibleDelivery: true,
            isVisibleContacts: true,
            clickedComment: false,
            clickedPromocode: false,
            clickedPayments: false,
            clickedDelivery: false,
            clickedContacts: false,
            observer: null,
            lastScrollY: 0,
            statusPaymentCreate: true,
            isButtonOrderDisabled: false,
            managerCall: 0,
            cart: [],
            emailValid: true,
            phoneValid: false,
        };
    },
    watch: {},
    methods: {
        getUTMData() {
            const cookies = document.cookie.split(";");
            let utmData = {};

            for (let i = 0; i < cookies.length; i++) {
                const [name, value] = cookies[i].trim().split("=");
                if (name === "utm") {
                    utmData = JSON.parse(decodeURIComponent(value));
                    break;
                }
            }

            return utmData;
        },

        phoneValidate(status) {
            this.phoneValid = status.valid;
        },

        phoneChangeCountry(country) {
            this.fieldPhone = "+" + country.dialCode;
        },

        changeTab(index) {
            this.activeTab = index;
        },
        createPayMethod(status) {
            if (!status) {
                this.statusPaymentCreate = false;
            }
        },
        handlePayMethod(method) {
            this.selectedPayMethod = method;
            if (!this.statusPaymentCreate) {
                this.toggleIsVisiblePayments();
            }
        },

        handleCheckboxChange(key, value) {
            this[key] = value ? 1 : 0; // Обновляет свойство компонента на основе ключа
        },

        async fbAndGoogleConversion(orderId) {
            const totalPrice = computed(() => this.$store.getters.totalPriceCart);

            const apiUrl = process.env.VUE_APP_PROD_API_URL;

            const event_id = this.$getEventId();
            const external_id = localStorage.getItem("external_id");

            // Получаем ID заказа
            let products = null;

            // Получаем товары из localStorage
            let storedProducts = JSON.parse(localStorage.getItem("cart") || "[]");

            // Преобразуем их в нужный формат
            products = storedProducts.map((product) => {
                return {
                    item_name: product.product.name,
                    item_id: product.product.vendor_code,
                    vendor_code: product.vendor_code ?? product.product.vendor_code,
                    price: product.variant?.price ? product.variant.price : product.product.price,
                    item_brand: "Le-Perle",
                    item_category: product.product.category?.title?.ua || (product.product?.category && product.product.category.length > 0 ? product.product.category[0]?.title : "") || "",
                    item_variant: product.variant.vendor_code ? product.variant.vendor_code : product.product.vendir_code,
                    quantity: product.quantity,
                };
            });

            // Вычисляем общую стоимость и количество товаров
            const totalValue = products.reduce((acc, product) => acc + product.price * product.quantity, 0);
            const totalItems = products.reduce((acc, product) => acc + product.quantity, 0);

            // Собираем ID продуктов
            const productIds = products.map((product) => product.vendor_code);

            // Отправляем событие в Facebook Pixel
            window.fbq("track", "Purchase", {
                value: totalValue, // Общая стоимость заказа
                currency: "UAH", // Валюта
                content_ids: productIds, // ID продуктов
                content_type: "product", // Тип контента
                event_id: event_id,
                order_id: orderId,
                external_id: external_id,
                num_items: totalItems, // Общее количество товаров
            });

            dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
            dataLayer.push({
                event: "purchase",
                ecommerce: {
                    transaction_id: orderId,
                    value: totalPrice.value,
                    currency: "UAH",
                    items: products,
                },
            });

            try {
                await axios.post(apiUrl + "fbapi", {
                    action: "Purchase",
                    event_id: event_id,
                    num_items: totalItems, // Общее количество товаров
                    content_ids: productIds, // ID продуктов
                    value: totalValue,
                    phone: localStorage.getItem("phone"),
                    email: localStorage.getItem("email"),
                    fbc: this.$getCookie("_fbc"),
                    fbp: this.$getCookie("_fbp"),
                    order_id: orderId,
                    external_id: external_id,
                    useragent: window.navigator.userAgent,
                    url: `${window.location.origin}${window.location.pathname}`,
                });
            } catch (error) {
                console.error(error);
            }
        },
        async submitOrder() {
            this.attemptedSubmit = true;

            const fieldNamesTranslations = {
                firstName: "Ім'я",
                lastName: "Прізвище",
                phone: "Телефон",
                selectedCity: "Оберіть місто",
                selectedDepartment: "Оберіть відділення",
                expressDeliveryCity: "Місто кур'єрської доставки",
                expressDeliveryAddress: "Адреса кур'єрської доставки",
                urkposhtaDeliveryCities: "Місто доставки",
                urkposhtaDeliveryIndex: "Поштовий індекс",
                fieldsurname: "По-батькові",
                urkposhtaDeliveryDepartment: "Адреса",
                internationalCountryName: "Країна",
                internationalDeliveryCities: "Місто доставки",
                internationalDeliveryIndex: "Індекс доставки",
                internationalDeliveryDepartment: "Адрес доставки",
            };
            // Вызываем validateForm и получаем результат
            const validationResult = this.validateForm();

            // Проверяем, прошла ли форма валидацию
            if (!validationResult.isValid) {
                // Собираем читаемые названия пустых полей в формате HTML
                const readableEmptyFields = validationResult.emptyFields.map((field) => `<strong>${fieldNamesTranslations[field]}</strong>`).join("<br>");

                // Выводим сообщение с перечнем пустых полей на русском, используя HTML для форматирования
                this.toast.error(`Будь ласка, заповніть всі обов'язкові поля:<br>${readableEmptyFields}.`);
                window.scrollTo({ top: 0, behavior: "smooth" });
                return;
            }

            // Предполагаем, что метод validateEmail изменяет состояние emailValid напрямую
            this.validateEmail(this.fieldEmail);

            if (this.fieldEmail.length > 0 && !this.emailValid) {
                this.toast.error("Введіть коректну електронну адресу.");
                return;
            }

            localStorage.setItem("phone", this.$refs.phone.value);
            localStorage.setItem("email", this.$refs.email.value);

            // Извлечение UTM-данных
            const utmData = this.getUTMData();

            // Сбор данных формы
            const formData = this.collectFormData();

            // Добавление UTM-данных к formData
            Object.keys(utmData).forEach((key) => {
                formData[key] = utmData[key];
            });

            try {
                this.isButtonOrderDisabled = true;
                const response = await axios.post(this.apiUrl + "order/create", formData);

                if (response.data.success) {
                    await this.fbAndGoogleConversion(response.data.order_id);

                    switch (response.data.method_pay) {
                        case "mono":
                            window.location.href = response.data.url;
                            break;
                        case "wayforpay": {
                            const formHTML = response.data.html;
                            document.getElementById("formContainer").innerHTML = formHTML;
                            const form = document.querySelector("#formContainer form");
                            if (form) {
                                form.submit();
                            }
                            break;
                        }
                        default:
                            window.location.href = response.data.url;
                            break;
                    }
                }
            } catch (error) {
                console.error("Ошибка при отправке данных", error);
                // Можно также использовать toast для вывода сообщения об ошибке
                this.toast.error("Сталася помилка при відправці даних.");
            } finally {
                // Сбросить флаг блокировки кнопки отправки в любом случае
                // this.isButtonOrderDisabled = false;
            }
        },
        toggleIsVisibleComment() {
            this.isVisibleComment = !this.isVisibleComment;
        },
        toggleIsVisiblePromocode() {
            this.isVisiblePromocode = !this.isVisiblePromocode;
        },
        toggleIsVisiblePayments(clicked) {
            if (!this.statusPaymentCreate) {
                if (clicked) this.clickedPayments = clicked;
                this.isVisiblePayments = !this.isVisiblePayments;
            }
        },
        toggleIsVisibleDelivery(clicked) {
            if (!this.deliveryData) return;
            const activeTab = this.deliveryData.activeTab;
            switch (activeTab) {
                case "tab1":
                    if (this.deliveryData.selectedCity && this.deliveryData.selectedDepartment) {
                        if (clicked) this.clickedDelivery = clicked;
                        this.isVisibleDelivery = !this.isVisibleDelivery;
                    }
                    break;
                case "tab3":
                    if (this.deliveryData.expressDeliveryCity && this.deliveryData.expressDeliveryAddress) {
                        if (clicked) this.clickedDelivery = clicked;
                        this.isVisibleDelivery = !this.isVisibleDelivery;
                    }
                    break;
                case "tab4":
                    if (this.deliveryData.urkposhtaDeliveryCities && this.deliveryData.urkposhtaDeliveryDepartment && this.deliveryData.urkposhtaDeliveryIndex && this.deliveryData.fieldsurname) {
                        if (clicked) this.clickedDelivery = clicked;
                        this.isVisibleDelivery = !this.isVisibleDelivery;
                    }
                    break;
                case "tab5":
                    if (this.deliveryData.internationalCountryName && this.deliveryData.internationalDeliveryCities && this.deliveryData.internationalDeliveryIndex && this.deliveryData.internationalDeliveryDepartment && this.deliveryData.fieldsurname) {
                        if (clicked) this.clickedDelivery = clicked;
                        this.isVisibleDelivery = !this.isVisibleDelivery;
                    }
                    break;
            }
        },
        toggleIsVisibleContacts(clicked) {
            if (this.fieldfirstName.length > 0 && this.fieldlastName.length > 0 && this.fieldEmail.length > 0 && this.fieldPhone.length > 0 && this.phoneValid) {
                if (clicked) this.clickedContacts = clicked;
                this.isVisibleContacts = !this.isVisibleContacts;
            }
        },

        validateForm() {
            const fieldValues = {
                firstName: this.$refs.firstName ? this.$refs.firstName.value.trim() : "",
                lastName: this.$refs.lastName ? this.$refs.lastName.value.trim() : "",
                // phone: this.fieldPhone && this.fieldPhone ? this.fieldPhone.trim() : "",
                phone: this.phoneValid,
            };

            let emptyFields = [];

            Object.entries(fieldValues).forEach(([fieldName, value]) => {
                if (!value) {
                    emptyFields.push(fieldName);
                }
            });

            let deliveryIsValid = true;

            if (this.deliveryData) {
                switch (this.deliveryData.activeTab) {
                    case "tab1":
                        if (!this.deliveryData.selectedCity) {
                            emptyFields.push("selectedCity");
                            deliveryIsValid = false;
                        }
                        if (!this.deliveryData.selectedDepartment) {
                            emptyFields.push("selectedDepartment");
                            deliveryIsValid = false;
                        }
                        break;
                    case "tab3":
                        if (!this.deliveryData.expressDeliveryCity) {
                            emptyFields.push("expressDeliveryCity");
                            deliveryIsValid = false;
                        }
                        if (!this.deliveryData.expressDeliveryAddress) {
                            emptyFields.push("expressDeliveryAddress");
                            deliveryIsValid = false;
                        }
                        break;
                    case "tab4":
                        if (!this.deliveryData.urkposhtaDeliveryCities) {
                            emptyFields.push("urkposhtaDeliveryCities");
                            deliveryIsValid = false;
                        }
                        if (!this.deliveryData.urkposhtaDeliveryDepartment) {
                            emptyFields.push("urkposhtaDeliveryDepartment");
                            deliveryIsValid = false;
                        }
                        if (!this.deliveryData.urkposhtaDeliveryIndex) {
                            emptyFields.push("urkposhtaDeliveryIndex");
                            deliveryIsValid = false;
                        }
                        if (!this.deliveryData.fieldsurname) {
                            emptyFields.push("fieldsurname");
                            deliveryIsValid = false;
                        }
                        break;
                    case "tab5":
                        if (!this.deliveryData.internationalCountryName) {
                            emptyFields.push("internationalCountryName");
                            deliveryIsValid = false;
                        }
                        if (!this.deliveryData.internationalDeliveryCities) {
                            emptyFields.push("internationalDeliveryCities");
                            deliveryIsValid = false;
                        }
                        if (!this.deliveryData.internationalDeliveryIndex) {
                            emptyFields.push("internationalDeliveryIndex");
                            deliveryIsValid = false;
                        }
                        if (!this.deliveryData.internationalDeliveryDepartment) {
                            emptyFields.push("internationalDeliveryDepartment");
                            deliveryIsValid = false;
                        }
                        if (!this.deliveryData.fieldsurname) {
                            emptyFields.push("fieldsurname");
                            deliveryIsValid = false;
                        }
                        break;
                    default:
                        // Можно обработать другие случаи, если они есть
                        break;
                }
            } else {
                deliveryIsValid = false;
            }

            return {
                isValid: emptyFields.length === 0 && deliveryIsValid,
                emptyFields,
            };
        },

        handleDeliveryData(deliveryData) {
            this.deliveryData = deliveryData; // Обновляем данные о доставке
            // Непосредственно обновляем cashlessPaymentsOnly, используя .value, так как это ref
            this.updateCashlessPaymentsOnly(deliveryData.activeTab === "tab5");

            if (this.deliveryData.activeTab == "tab1") {
                this.toggleIsVisibleDelivery();
            }
        },

        collectFormData() {
            const formData = {
                firstName: this.$refs.firstName.value,
                lastName: this.$refs.lastName.value,
                phone: this.fieldPhone,
                email: this.$refs.email.value,
                cart: JSON.parse(localStorage.getItem("cart")),
                deliveryData: this.deliveryData, // добавьте данные о доставке
                method_pay: this.selectedPayMethod,
                promoCode: this.$refs.promoCode.value,
                comment: this.$refs.comment.value,
                managerCall: this.managerCall, // Значения чекбокса
                // Добавьте другие необходимые поля
            };
            return formData;
        },

        isValid(fieldRef) {
            const field = this.$refs[fieldRef];
            return field && field.value;
        },
        async checkPromoCode() {
            const promoCode = this.$refs.promoCode.value; // получаем текущее значение из input
            if (!promoCode) return;
            if (promoCode !== "SCHOOL10" && promoCode !== "HB10" && promoCode.length != 7) return;

            try {
                const response = await axios.get(this.apiUrl + "promocode/" + promoCode);
                const promocodeStatusElem = document.getElementById("promocodeStatus");

                if (response.data.error) {
                    promocodeStatusElem.textContent = "Промокод був використаний";
                    promocodeStatusElem.style.color = "red";

                    this.discountType = null;
                    this.discountData = 0;
                } else {
                    promocodeStatusElem.textContent = "Промокод активований";
                    promocodeStatusElem.style.color = "green";

                    this.discountType = response.data.type; // 3 или какой-то другой процент
                    this.discountData = response.data.data; // 3 или какой-то другой процент
                }
            } catch (error) {
                console.error(error);
            }
        },

        beforeEnter(el) {
            el.style.maxHeight = "0";
        },
        enter(el) {
            el.style.maxHeight = `${el.scrollHeight}px`;
            el.style.overflow = "unset";
        },
        beforeLeave(el) {
            el.style.maxHeight = `${el.scrollHeight}px`;
            el.style.overflow = "unset";
        },
        leave(el) {
            el.style.maxHeight = "0";
            el.style.overflow = "hidden";
        },
        fieldsWrapperContacts() {
            if (this.isVisibleContacts && this.fieldfirstName.length > 0 && this.fieldlastName.length > 0 && this.fieldPhone.length > 0) {
                this.toggleIsVisibleContacts();
            }
        },
        fieldsWrapperDelivery() {
            if (this.isVisibleDelivery && !this.clickedDelivery) {
                this.toggleIsVisibleDelivery();
            }
        },
        validateEmail(email) {
            const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            this.emailValid = re.test(email);
        },
    },
    mounted() {
        this.cart = JSON.parse(localStorage.getItem("cart") || "[]");
    },
    beforeUnmount() {},
};
</script>

<style scoped>
.order-epmty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
    flex-direction: column;
}
.vue-tel-input {
    padding-left: 10px;
    border: 1px solid rgba(0, 0, 0, 0.7) !important;
    display: flex;
    align-items: center;
}
.input-error {
    border: 1px solid red !important;
}

.vti__input {
    padding: 5px;
}
</style>
