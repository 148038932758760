// toFixed.js
export default {
    install(app) {
        app.config.globalProperties.$toFixed = (value, digits = 2) => {
            if (!isNaN(parseFloat(value)) && isFinite(value)) {
                return Number(value).toFixed(digits);
            }
            return '0.00'; // или другое значение по умолчанию
        };
    },
};
