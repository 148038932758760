<template>
    <article class="product-cart">
        <div class="product-cart__images-ibg">
            <router-link :to="{ name: 'Product', params: { slug: product.slug } }"><img :src="getImageProduct(product)" alt="" /></router-link>
            <div class="product-cart__labels">
                <button class="product-cart__label _icon-close" @click="removeFromWishlist(product)"></button>
            </div>
        </div>
        <div class="product-cart__info">
            <h3 class="product-cart__title">
                <router-link :to="{ name: 'Product', params: { slug: product.slug } }">{{ product.name }}</router-link>
            </h3>
            <div class="product-cart__materials">
                <!-- <span>Перламутр,</span> -->
                <span>позолота 18к</span>
            </div>
        </div>
        <div class="product-cart__price price">
            <div class="price__current sale">{{ Math.round(product.price) }} грн.</div>
            <div class="price__old" v-if="product.old_price">{{ product.old_price }} грн.</div>
        </div>
        <button class="product-cart__button button" @click="addToCart(product)">Додати в кошик</button>
    </article>
</template>

<script>
// import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    setup() {
        // const store = useStore();
        const router = useRouter();

        const addToCart = (product) => {
            router.push({ name: "Product", params: { slug: product.slug } });
            // const hasVariants = product.variants && product.variants.length > 0;

            // if (hasVariants) {
            //     router.push({ name: "Product", params: { slug: product.slug } });
            // } else {
            //     let cart = localStorage.getItem("cart");
            //     if (cart) {
            //         cart = JSON.parse(cart);
            //     } else {
            //         cart = [];
            //     }

            //     const itemToAdd = {
            //         id: product.id,
            //         product_id: product.id,
            //         variant_id: null,
            //         quantity: 1,
            //         product: product,
            //         variant: [],
            //         is_set: 0,
            //     };

            //     const existingProductIndex = cart.findIndex((item) => item.id === itemToAdd.id);
            //     if (existingProductIndex >= 0) {
            //         cart[existingProductIndex].quantity++;
            //     } else {
            //         cart.push(itemToAdd);
            //     }

            //     localStorage.setItem("cart", JSON.stringify(cart));
            //     document.dispatchEvent(new CustomEvent("cart-updated"));
            //     store.commit("openCart");
            // }
        };

        return { addToCart };
    },

    methods: {
        getImageProduct(product) {
            return product.downloads[0]?.path ? this.$uploadUrl + product.downloads[0].path : "https://api.le-perle.com.ua/assets/img/logo.svg";
        },
        removeFromWishlist(product) {
            let wishlist = localStorage.getItem("wishlist");
            if (wishlist) {
                wishlist = JSON.parse(wishlist);
                // Удаляем продукт из списка
                wishlist = wishlist.filter((wishItem) => wishItem.id !== product.id);
                localStorage.setItem("wishlist", JSON.stringify(wishlist));

                // Сообщаем родительскому компоненту, что товар был удален
                this.$emit("removedFromWishlist");
            }
        },
    },
};
</script>

<style></style>
